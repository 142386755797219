import React from 'react';
import {
    Button, Input, Select, Table, Tag, message, Modal
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getBMCCoreList, processorReset, idcCodeList } from '../../api';
import ColumnSetting from '../../components/ColumnSetting';
// import { util } from '../../_helpers/util';
const { Option } = Select;

let columns = [];
let buttonArr = [];
class DevicePage extends React.Component {
    state = {
        machine: '',
        loading: false,
        selectedRowKeys: [], // 选择的列表项
        selectedRows: [], // 选择项数据数组
        BMCList: [], // BMC列表
        socList: [],
        processorIndex: null, // 核心板卡位
        processorPowerStatus: null,
        processorSn: '', // 核心板SN
        processorInstallStatus: null,
        bmcSn: '',
        essorIp: '',
        pageSize: 10,
        pageNo: 1,
        total: 0,
        column: [],
        processorModel: '',
        confirmLoading: false,
        showTableLoading: false,
        showSearchLoading: false,
        modalTitle: '',
        modalContent: '',
        otherContent: '',
        showModal: false,
        handleOk: () => {
        },
        handleCancel: () => {
        }
    };

    componentDidMount() {
        this.fetchSocList();
        this.fetchBMCCoreList();
        this.init();
    }

    componentWillReceiveProps() {
        this.setState({
            processorPowerStatus: null,
            processorIndex: null,
            processorSn: '',
            processorModel: '',
            processorInstallStatus: null,
            pageSize: 10,
            pageNo: 1
        }, () => {
            this.fetchBMCCoreList();
        });
    }

    init = () => {
        let treeList = JSON.parse(localStorage.getItem('permitTree'));
        let buttonList = [];

        treeList && treeList.map((v, i) => {
            if (v.menuCode === 'processor_management') {
                buttonList = v.childMenuList;
            }
        });
        buttonArr = buttonList && buttonList.map(v => {
            return v.menuCode;
        });
        const ForMatStatus = (props) => {
            const { status } = props;
            let maps = {
                '在位': 'blue',
                '未在位': 'volcano',
                '正常': 'blue',
                '异常': 'volcano'
            };
            return <Tag color={maps[status]}>
                {status}</Tag>;
        };
        columns = [{
            title: '核心板卡位',
            dataIndex: 'processorIndex',
            align: 'center',
            fixed: 'left'
        }, {
            title: 'IP地址',
            key: 'processorIp',
            dataIndex: 'processorIp',
            align: 'center',
        }, {
            title: '在位状态',
            key: 'processorInstallStatus',
            dataIndex: 'processorInstallStatus',
            align: 'center',
            render: function (text, record, index) {
                return <ForMatStatus status={text === 0 ? '未在位' : '在位'} />;
            }
        }, {
            title: '电源状态',
            dataIndex: 'processorPowerStatus',
            align: 'center',
            render: function (text, record, index) {
                return <ForMatStatus status={text === 0 ? '异常' : '正常'} />;
            }
        }, {
            title: '板卡型号',
            dataIndex: 'processorModel',
            align: 'center'
        }, {
            title: '板卡序列号',
            dataIndex: 'processorSn',
            align: 'center'
        }, {
            title: '操作系统信息',
            dataIndex: 'processorSystemInfo',
            align: 'center'
        }, {
            title: '内核版本',
            dataIndex: 'processorKernelVersion',
            align: 'center'
        }, {
            title: 'MCU版本号',
            key: 'processorFirmware',
            dataIndex: 'processorFirmware',
            align: 'center',
        }, {
            title: 'MAC地址',
            key: 'processorMac',
            dataIndex: 'processorMac',
            align: 'center',
        }];
        if (this.props.page === 'manage') {
            columns.splice(3, 0, {
                title: 'BMC编号',
                key: 'bmcSn',
                dataIndex: 'bmcSn',
                align: 'center',
            });
        }
        if (buttonArr.indexOf('processor_restart') > -1) {
            columns.push({
                title: '操作',
                key: 'action',
                align: 'center',
                fixed: 'right',
                render: (text, record) => (
                    <div>
                        <a onClick={this.BMCArmReset.bind(this, record, 'edit')}>断电重启</a>
                    </div >
                )
            });
        }
        this.setState({
            column: columns
        });
    };
    fetchBMCCoreList = async () => {
        const { processorIndex, processorPowerStatus, processorInstallStatus, processorSn,
            processorModel, bmcSn, processorIp } = this.state;
        const { bmcId } = this.props;
        let params = {
            bmcId,
            processorIndex,
            processorPowerStatus,
            processorSn,
            processorModel,
            processorInstallStatus,
            processorIp,
            bmcSn
        };
        this.setState({
            showTableLoading: true,
            showSearchLoading: true
        });
        try {
            const res = await getBMCCoreList(params);
            this.setState({
                BMCList: res,
                showSearchLoading: false,
                showTableLoading: false
            });
        } catch (e) {
            this.setState({
                showSearchLoading: false,
                showTableLoading: false
            });
        }


    };
    fetchSocList = async () => {
        const res = await idcCodeList({ emType: 'soc' });
        this.setState({
            socList: res
        });
    };

    // exportDevice = async () => {
    //     const {
    //         processorIndex, processorPowerStatus, bmcSn, processorSn, processorModel
    //     } = this.state;
    //     let params = {
    //         processorIndex,
    //         processorPowerStatus,
    //         bmcSn,
    //         processorSn,
    //         processorModel
    //     };
    //     try {
    //         let data = await exportDeviceList(params);
    //         let blob = new Blob([data]);
    //         const excelName = '设备列表.xlsx';
    //         util.downloadBolb(blob, excelName);
    //     } catch (e) {

    //     }
    // };


    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
        this.setState({ selectedRowKeys, selectedRows });
    };

    onSearch = async (key) => {

    };
    onPageChange = (page, pageSize) => {
        this.setState({
            pageNo: page,
            pageSize
        }, () => {
            this.fetchBMCCoreList();
        });
    };


    // 重启核心板
    BMCArmReset = (record) => {
        let array = [];
        this.setState({
            showModal: true,
            modalTitle: '核心板断电重启',
            modalContent: <p>核心板断电后可能导致设备无法正常使用，是否继续对核心板断电？</p>,
            handleOk: async () => {
                if (record.type && record.type === 'all') {
                    const { selectedRows } = this.state;
                    selectedRows.map((item, i) => {
                        let temp = {};
                        temp.processorIndex = item.processorIndex;
                        temp.manageIp = item.manageIp;
                        array.push(temp);
                    });

                } else {
                    const { processorIndex, manageIp } = record;
                    let temp = {};
                    temp.processorIndex = processorIndex;
                    temp.manageIp = manageIp;
                    array.push(temp);
                }

                try {
                    await processorReset(array);
                    message.success('核心板已断电');
                } catch (error) {
                }
                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };


    // 搜索项变化触发
    searchInfoChange = (type, e) => {
        let { processorIndex, processorPowerStatus, processorSn, processorModel, processorInstallStatus,
            processorIp, bmcSn } = this.state;
        let value;
        if (e) {
            value = e.target ? (e.target.value).trim() : e;
        } else {
            value = e;
        }
        ;
        switch (type) {
            case 'processorIndex': {
                processorIndex = value;
                break;

            }
            case 'processorPowerStatus': {
                processorPowerStatus = value;
                break;
            }
            case 'processorInstallStatus': {
                processorInstallStatus = value;
                break;

            }
            case 'processorSn': {
                processorSn = value;
                break;
            }
            case 'processorModel': {
                processorModel = value;
                break;

            }
            case 'processorIp': {
                processorIp = value;
                break;
            }
            case 'bmcSn': {
                bmcSn = value;
                break;

            }

        }

        this.setState({
            pageNo: 1,
            processorIndex,
            processorInstallStatus,
            processorPowerStatus,
            processorSn,
            processorModel,
            bmcSn,
            processorIp
        }, () => {
            if (type === 'processorPowerStatus' || type === 'processorModel' || type === 'processorInstallStatus') {
                this.search();
            }
        });
    };

    search = () => {
        this.setState({
            pageNo: 1,
            selectedRowKeys: [],
            selectedRows: []
        }, () => {
            this.fetchBMCCoreList();
        });

    };

    render() {
        const cssPre = 'device';
        const {
            loading, otherContent, processorSn, selectedRowKeys, confirmLoading, BMCList, processorIp, bmcSn,
            showTableLoading, showSearchLoading, pageNo, processorPowerStatus, processorInstallStatus, column,
            total, showModal, handleCancel, handleOk, modalContent, modalTitle,
            processorModel, socList, pageSize, } = this.state;
        const { page } = this.props;
        BMCList && BMCList.map((v, i) => {
            v.key = i;
        });
        const culumnsData = columns.map((item) => {
            return item.title;
        });
        const culumnsSelectData = column.map((item) => {
            return item.title;
        });
        const columnsChange = (list) => {
            let temp = [];
            temp = columns.filter((item) => {
                return list.indexOf(item.title) !== -1;
            });
            this.setState({
                column: temp
            });
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-content`}>
                    <div className=''>
                        {buttonArr.indexOf('processor_list_search') > -1 &&
                            <div className='search'>
                                <Input.Group compact className='search-wrapper'>
                                    <div className='left'>
                                        <div className='search-ida'>
                                            <span className='title'>核心板IP：</span>
                                            <Input placeholder='输入核心板IP' allowClear
                                                className='search-device-name'
                                                value={processorIp}
                                                style={{ width: 150, marginBottom: 20 }}
                                                onChange={this.searchInfoChange.bind(this, 'processorIp')} />
                                        </div>

                                        <div className='search-ida'>
                                            <span className='title'>板卡序列号：&nbsp;&nbsp;</span>
                                            <Input placeholder='输入板卡序列号' allowClear
                                                className='search-device-name'
                                                value={processorSn}
                                                style={{ width: 150, marginBottom: 20 }}
                                                onChange={this.searchInfoChange.bind(this, 'processorSn')} />
                                        </div>
                                        {page === 'manage' &&
                                            <div className='search-ida'>
                                                <span className='title'>BMC编号：</span>
                                                <Input placeholder='输入BMC编号' allowClear
                                                    className='search-device-name'
                                                    value={bmcSn}
                                                    style={{ width: 150, marginBottom: 20 }}
                                                    onChange={this.searchInfoChange.bind(this, 'bmcSn')} />
                                            </div>
                                        }
                                        <div className='search-ida'>
                                            <span className='title'>板卡型号：</span>
                                            {/* <Select onChange={
                                                this.searchInfoChange.bind(this, 'processorModel')}
                                                showSearch
                                                style={{ width: 150, marginBottom: 20 }}
                                                value={processorModel}
                                                placeholder='请选择运行状态'>
                                                <Option value='845'>845</Option>
                                                <Option value='865'>865</Option>
                                                <Option value=''>845和865混合</Option>
                                            </Select> */}
                                            <Select
                                                onChange={this.searchInfoChange.bind(this, 'processorModel')}
                                                style={{ width: 150 }}
                                                value={processorModel}
                                                placeholder='请选择板卡型号'>
                                                <Option value=''>所有</Option>
                                                {
                                                    socList && socList.map((item, index) => {
                                                        return <Option value={item.emValue}>{item.emKey}</Option>;
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <div className='search-ida'>
                                            <span className='title'>在位状态：</span>
                                            <Select onChange={
                                                this.searchInfoChange.bind(this, 'processorInstallStatus')}
                                                showSearch
                                                style={{ width: 150 }}
                                                value={processorInstallStatus}
                                                placeholder='请选择在线状态'>
                                                <Option value={null}>所有</Option>
                                                <Option value={0}>未在位</Option>
                                                <Option value={1}>正常</Option>
                                            </Select>
                                        </div>
                                        <div className='search-ida'>
                                            <span className='title'>电源状态：</span>
                                            <Select onChange={
                                                this.searchInfoChange.bind(this, 'processorPowerStatus')}
                                                showSearch
                                                style={{ width: 150 }}
                                                value={processorPowerStatus}
                                                placeholder='请选择电源状'>
                                                <Option value={null}>所有</Option>
                                                <Option value={0}>异常</Option>
                                                <Option value={1}>正常</Option>
                                            </Select>
                                        </div>
                                        <Button type='primary' icon={<SearchOutlined />} loading={showSearchLoading}
                                            onClick={this.search}>查询</Button>
                                        {/* <Button type='primary' icon={expansionType === 'close' ?
                                        <DownOutlined/> : <UpOutlined/>} loading={showSearchLoading}
                                            onClick={this.expansion} style={{marginLeft: 10}}>
                                        {expansionType === 'open' ? '收起' : '展开'}
                                    </Button> */}
                                    </div>
                                    <div className='right'></div>

                                </Input.Group>
                            </div>
                        }
                        <div className='operation'>
                            <div className='left'>

                                {buttonArr.indexOf('processor_restart') > -1 &&

                                    <Button type='primary' onClick={this.BMCArmReset.bind(this, { type: 'all' })}
                                        disabled={!hasSelected} loading={loading}>
                                        断电重启
                                    </Button>
                                }
                                {/* {buttonArr.indexOf('device_list_export') > -1 &&
                                    <Button type='primary' style={{ marginLeft: 10 }} onClick={this.exportDevice}>
                                        导出
                                    </Button>
                                } */}
                                <span style={{ marginLeft: 8, width: '82px' }}>
                                    {hasSelected ? `已选择${selectedRowKeys.length}项` : ''}
                                </span>
                            </div>
                            <div className='right'>
                                <ColumnSetting options={culumnsData} selectedRowKeys={culumnsSelectData}
                                    onChange={columnsChange}></ColumnSetting>
                            </div>
                        </div>
                    </div>

                    <div className='result'>
                        <Table
                            rowSelection={rowSelection}
                            columns={column}
                            dataSource={BMCList}
                            scroll={{ x: 1300 }} sticky
                            loading={showTableLoading}
                            showSorterTooltip={false}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                current: pageNo,
                                pageSize,
                                showTotal: total => {
                                    return `总计${total}项`;
                                },
                                onChange: this.onPageChange,
                                total: total
                            }}
                        />
                    </div>
                </div>
                <Modal title={modalTitle} visible={showModal} onOk={handleOk} onCancel={handleCancel} okText='确认'
                    cancelText='取消'
                    destroyOnClose={true}
                    confirmLoading={confirmLoading}>
                    {modalContent}
                    {otherContent}
                </Modal>
            </div>
        );
    }
}

export default DevicePage;

