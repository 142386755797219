import { getAuth, getPermitTree } from '../api';
import qs from 'qs';
export const userService = {
    login,
    logout,
};

async function login(username, password, sms_verify_code = '', mode) {
    let params = {
        username,
        password,
        sms_verify_code,
        grant_type: 'password',
        client_id: 'bmc_platform_frontend',
        client_secret: 'bmc_platform_secret'
    };
    if (mode === 'sms') {
        params.auth_type = 'sms';
    }
    params = qs.stringify(params);
    const res = await getAuth(params);
    let user = {
        id: res.id,
        username: username,
        token: res.accessToken
    };
    localStorage.setItem('user', JSON.stringify(user));
    const resPermit = await getPermitTree({});
    localStorage.setItem('permitTree', JSON.stringify(resPermit));
    return user;
}
function logout() {
    // 退出登录
    localStorage.removeItem('user');
}

