import { request } from '../_helpers';
import { config } from '../config';

let baseURL = config.apiUrl;
// 获取以及搜索BMC列表
export async function getDeviceList(params) {
    return request.post(`${baseURL}/acb/manage/bmc/list`, params);
};

// 添加BMC
export async function addBMC(params) {
    return request.post(`${baseURL}/acb/manage/bmc/add`, params);
};
// 编辑BMC
export async function editBMC(params) {
    return request.post(`${baseURL}/acb/manage/bmc/update`, params);
};
// 删除BMC
export async function deleteBMC(params) {
    return request.get(`${baseURL}/acb/manage/bmc/delete`, params);
};
// BMC详情
export async function getBMCDetail(params) {
    return request.get(`${baseURL}/acb/manage/bmc/detail`, params);
};
// 功率历史趋势
export async function getBMCPowerTrend(params) {
    return request.post(`${baseURL}/acb/manage/bmc/power/trend`, params);
};
// BMC核心板列表
export async function getBMCCoreList(params) {
    return request.post(`${baseURL}/acb/manage/bmc/core/list`, params);
};
// 机房IDC_CODE枚举获取
export async function idcCodeList(params) {
    return request.get(`${baseURL}/acb/manage/bmc/common/enum/list`, params);
};
// 防火墙枚举获取
export async function firewallList(params) {
    return request.get(`${baseURL}/acb/manage/bmc/common/enum/list/byValue`, params);
};
// 设备列表导出
export async function exportDeviceList(params) {
    return request.post(`${baseURL}/aca/manage/v2/device/list/export`, params, {
        responseType: 'blob'
    });
};
// BMC开断电
export async function BMCPower(params) {
    return request.get(`${baseURL}/acb/manage/bmc/power`, params);
};

// BMC重启
export async function BMCReset(params) {
    return request.get(`${baseURL}/acb/manage/bmc/reset`, params);
};
// BMC整机重启
export async function BMCArmReset(params) {
    return request.get(`${baseURL}/acb/manage/bmc/arm/reset`, params);
};
// BMC数据概览页
export async function getBMCView(params) {
    return request.get(`${baseURL}/acb/manage/bmc/general/view`, params);
};
// 核心板重启(支持批量)
export async function processorReset(params) {
    return request.post(`${baseURL}/acb/manage/bmc/processor/reset`, params);
};
// BMC模板导出
export async function exportTemplate(params) {
    return request.get(`${baseURL}/acb/manage/bmc/downloadImportTemplate`, params, {
        responseType: 'blob'
    });
};
// 上传excel
export async function importTemplate(params) {
    return request.post_formData(`${baseURL}/acb/manage/bmc/batchImport`, params);
};

// 升级文件上传
export async function firmwireUpload(params) {
    return request.post_formData(`${baseURL}/acb/manage/bmc/firmwire/upload`, params);
};
// 升级指令
export async function firmwireUpgrade(params) {
    return request.post(`${baseURL}/acb/manage/bmc/firmwire/upgrade`, params);
};
// 升级状态查询
export async function firmwireStatus(params) {
    return request.post(`${baseURL}/acb/manage/bmc/firmwire/status`, params);
};
