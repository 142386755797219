import React from 'react';
// import { Breadcrumb } from 'antd';
import BMCCoreList from '../../components/BMC/core';

import './index.less';
class CorePage extends React.Component {
    state = {
    };

    async componentWillMount() {

    }

    render() {
        const cssPre = 'core';
        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-header`}>
                    <h4 className='title'>核心板管理</h4>
                    {/* <Breadcrumb>
                        <Breadcrumb.Item>核心板管理</Breadcrumb.Item>
                        <Breadcrumb.Item>核心板列表</Breadcrumb.Item>
                    </Breadcrumb> */}
                </div>
                <div className={`${cssPre}-content`}>
                    <BMCCoreList page='manage'></BMCCoreList>
                </div>
            </div>
        );
    }
}

export default CorePage;

