import React from 'react';
import { Row, Col, Descriptions, Divider, Card, DatePicker, Tooltip } from 'antd';
import { getBMCView } from '../../api';
import DrawFlowStaticsChart from '../../components/DrawFlowStaticsChart';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';


import moment from 'moment';

import './index.less';
let yesterdayStart = moment(new Date((new Date().getTime() - 60 * 60 * 1000)));
let yesterdayEnd = moment(new Date().getTime());
class HomePage extends React.Component {
	state = {
		detail: {},
		deviceType: 0,
		time: moment().format('YYYY年MM月DD日 HH:mm:ss'),
	};

	async componentDidMount() {
		this.fetchBMCView();
		setInterval(() => {
			this.setState({
				time: moment().format('YYYY年MM月DD日 HH:mm:ss')
			});
		}, 1000);
	}
	fetchBMCView = async () => {
		try {
			const res = await getBMCView();
			this.setState({
				detail: res,
			});
		} catch (e) {
		}
	}
	render() {
		const cssPre = 'home';
		const { detail, time } = this.state;
		return (
			<div className={`${cssPre}`}>
				<div className={`${cssPre}-header`}>
					<h4 className='title' >数据概览页</h4>
				</div>
				<div className={`${cssPre}-content`}>
					<div class='date'>时间： {time}</div>
					<div class='view'>
						<Descriptions title="" layout="vertical" labelStyle={{ color: '#02A7F0' }}>
							<Descriptions.Item label="BMC数量">{detail.bmcCount}台</Descriptions.Item>
							<Descriptions.Item label="BMC正常数量">{detail.bmcNormalCount}台</Descriptions.Item>
							<Descriptions.Item label="BMC异常数量">{detail.bmcErrorCount}台</Descriptions.Item>
							<Descriptions.Item label="核心板数量">{detail.coreCount}台</Descriptions.Item>
							<Descriptions.Item label="核心板正常数量">{detail.coreNormalCount}台</Descriptions.Item>
							<Descriptions.Item label="核心板异常数量">{detail.coreErrorCount}台</Descriptions.Item>
						</Descriptions>
						<Tooltip title={() => {
							return <>
								<p>BMC数量：所有添加的BMC数量总和。</p>
								<p>BMC正常数量：所有状态为“正常”的BMC数量。</p>
								<p>BMC异常数量：所有BMC或核心板有异常的BMC数量。异常类型包含：断电、电源异常、风扇异常、传感器异常、核心板异常</p>
								<p>核心板数量：所有核心板的数量。</p>
								<p>核心板正常数量：所有状态为“正常”的核心板数量。</p>
								<p>核心板异常数量：所有状态为“异常”的核心板数量。异常类型包含：断电、传感器异常、未获取到IP、未在位</p>
							</>;
						}}>

							<QuestionCircleOutlined style={{ color: 'rgb(2, 167, 240)', alignSelf: 'flex-start' }} />
						</Tooltip>
						<Divider type="vertical" style={{ height: 100 }} />
						{/*<Card title="" bordered={false} style={{ textAlign: 'center' }}>*/}
						{/*	<Row justify='center'>*/}
						{/*		<Col span={12}>紧急：</Col>*/}
						{/*		<Col span={12}>*/}
						{/*			<Link to={*/}
						{/*				{*/}
						{/*					pathname: '/console/monitor/list',*/}
						{/*					query: { level: 3 }*/}
						{/*				}} style={{ width: 40, fontSize: 12, color: '#D9001B' }}>*/}
						{/*				{detail.urgentCount}*/}
						{/*			</Link>*/}
						{/*		</Col>*/}
						{/*	</Row>*/}
						{/*	<Row justify='center'>*/}
						{/*		<Col span={12}>严重：</Col>*/}
						{/*		<Col span={12}>*/}
						{/*			<Link to={*/}
						{/*				{*/}
						{/*					pathname: '/console/monitor/list',*/}
						{/*					query: { level: 2 }*/}
						{/*				}} style={{ width: 40, fontSize: 12, color: '#F59A23' }}>*/}
						{/*				{detail.seriousCount}*/}
						{/*			</Link>*/}
						{/*		</Col>*/}
						{/*	</Row>*/}

						{/*	<Row justify='center'>*/}
						{/*		<Col span={12}>轻微：</Col>*/}
						{/*		<Col span={12}>*/}
						{/*			<Link to={*/}
						{/*				{*/}
						{/*					pathname: '/console/monitor/list',*/}
						{/*					query: { level: 1 }*/}
						{/*				}} style={{ width: 40, fontSize: 12, color: '#70B603' }}>*/}
						{/*				{detail.lightCount}*/}
						{/*			</Link>*/}
						{/*		</Col>*/}
						{/*	</Row>*/}
						{/*	/!* <Row justify='center'>*/}
						{/*		<Col>正常：</Col>*/}
						{/*		<Col>{detail.normalCount}</Col>*/}
						{/*	</Row> *!/*/}
						{/*</Card>*/}
						<Tooltip title={() => {
							return <>
								<p>各状态定义：</p>
								<p>紧急：BMC断电、电源异常、风扇异常</p>
								<p>严重：核心板断电、无法获取iP、未在位</p>
								<p>轻微:传感器异常</p>
							</>;
						}}>

							<QuestionCircleOutlined style={{ color: 'rgb(2, 167, 240)', alignSelf: 'flex-start' }} />
						</Tooltip>
					</div>
					<Card title="BMC状态趋势图" bordered={false} style={{ marginTop: 20 }}>
						<DrawFlowStaticsChart dataSource={detail}> </DrawFlowStaticsChart>
					</Card>
				</div>
			</div >
		);
	}
}

export default HomePage;

