import React from 'react';
import { Row, Col, Card, Tooltip, DatePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    getBMCDetail, getBMCPowerTrend
} from '../../api';
import { Link } from 'react-router-dom';
import { Charts } from './charts';
import moment from 'moment';

class BMCDetailPage extends React.Component {
    state = {
        detail: {},
        startTime: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        endTime: new Date(),
        data: [],
        currentPower: 0
    };

    async componentWillMount() {
        await this.fetchBMCDetail();
        await this.fetchPowerTrend();
    }
    fetchBMCDetail = async () => {
        const { bmcId } = this.props;
        try {
            let res = await getBMCDetail({ bmcId });
            this.setState({
                detail: res
            });
        } catch (e) {
        }
    }

    fetchPowerTrend = async () => {
        const { detail, startTime, endTime } = this.state;
        const manageIp = detail.ipv4Address;
        const formatDate = (d) => {
            const date = new Date(d);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${year}${month}${day}\n${hours}:${minutes}:${seconds}`;
        };
        try {
            let res = await getBMCPowerTrend({ manageIp, startTime, endTime });
            if (endTime - startTime > 60 * 60 * 24 * 30 * 1000) {
                res = res.filter((item, index) => (index % 6) === 0);
            }
            res.sort((a, b) => new Date(a.recordTime) - new Date(b.recordTime));
            let data = res.map((d) => {
                return {
                    '时间': formatDate(d.recordTime),
                    '功率（W）': d.powerData
                };
            });
            console.log('getBMCPowerTrendMock', data);

            let currentPower = 0;
            if (data.length !== 0) {
                currentPower = data[data.length - 1]['功率（W）'];
            } else {
                data = [{
                    '时间': 0,
                    '功率（W）': 0
                }];
            }

            this.setState({
                data,
                currentPower
            });
        } catch (e) {
        }
    }

    onRangeChange = (dates, dateStrings) => {
        if (dates) {
            this.setState({
                startTime: new Date(dateStrings[0]),
                endTime: new Date(dateStrings[1])
            }, async () => {
                await this.fetchPowerTrend();
            });
        } else {
            console.log('Clear');
        }
    };

    render() {
        const cssPre = 'detail';
        const { detail, data, currentPower, startTime, endTime } = this.state;
        return (
            <div className={`${cssPre}`}>

                <div className={`${cssPre}-content`}>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            {/*<Col span={12}>*/}
                            {/*    <Card title="状态信息" bordered={false} style={{ height: 320 }}>*/}
                            {/*        <Row justify='center' style={{ fontSize: 16, fontWeight: 'bold' }}>服务器电源</Row>*/}
                            {/*        <Row justify='center'>{detail.powerStatus ? '设备异常' : '设备状态良好，一切正常'}</Row>*/}
                            {/*        <Row justify='center' style={{ marginTop: 20 }}>*/}
                            {/*            <Col>紧急：</Col>*/}
                            {/*            <Col><Link to={*/}
                            {/*                {*/}
                            {/*                    pathname: '/console/monitor/list',*/}
                            {/*                    query: { level: 3 }*/}
                            {/*                }} style={{ width: 40, fontSize: 12, color: '#D9001B' }}>*/}
                            {/*                {detail.urgentCount}*/}
                            {/*            </Link></Col>*/}
                            {/*        </Row>*/}
                            {/*        <Row justify='center' style={{ marginTop: 20 }}>*/}
                            {/*            <Col>严重：</Col>*/}
                            {/*            <Col><Link to={*/}
                            {/*                {*/}
                            {/*                    pathname: '/console/monitor/list',*/}
                            {/*                    query: { level: 2 }*/}
                            {/*                }} style={{ width: 40, fontSize: 12, color: '#F59A23' }}>*/}
                            {/*                {detail.seriousCount}*/}
                            {/*            </Link></Col>*/}
                            {/*        </Row>*/}
                            {/*        <Row justify='center' style={{ marginTop: 20 }}>*/}
                            {/*            <Col>轻微：</Col>*/}
                            {/*            <Col><Link to={*/}
                            {/*                {*/}
                            {/*                    pathname: '/console/monitor/list',*/}
                            {/*                    query: { level: 1 }*/}
                            {/*                }} style={{ width: 40, fontSize: 12, color: '#70B603' }}>*/}
                            {/*                {detail.lightCount}*/}
                            {/*            </Link></Col>*/}
                            {/*        </Row>*/}
                            {/*        /!* <Row justify='center'>*/}
                            {/*            <Col>正常：</Col>*/}
                            {/*            <Col>{detail.normalCount}</Col>*/}
                            {/*        </Row> *!/*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <Col span={12}>
                                <Card title="基础信息" bordered={false} style={{ height: 320 }}>
                                    <Row>
                                        <Col>产品名称：</Col>
                                        <Col>{detail.productName}</Col>
                                    </Row>
                                    <Row style={{ marginTop: 20 }}>
                                        <Col>IPV4地址：</Col>
                                        <Col>{detail.ipv4Address}</Col>
                                    </Row>
                                    <Row style={{ marginTop: 20 }}>
                                        <Col>MAC地址：</Col>
                                        <Col>{detail.macAddress}</Col>
                                    </Row>
                                    <Row style={{ marginTop: 20 }}>
                                        <Col>HDM固件版本：</Col>
                                        <Col>{detail.bmcRevision}</Col>
                                    </Row>
                                    <Row style={{ marginTop: 20 }}>
                                        <Col>CPLD固件版本：</Col>
                                        <Col>{detail.cpldRevision}</Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: 16 }}>
                            <Col span={24}>
                                <Card title="服务器功率（W）" bordered={false} style={{height: 380}}>
                                    <span style={{marginRight: 30}}>
                                        当前功率：{currentPower}（W）
                                        <span style={{cursor: 'pointer'}}>
                                            <Tooltip
                                                placement="top"
                                                title={'每10分钟记录一次服务器功率数据'}
                                                trigger="hover"
                                            >
                                                <ExclamationCircleOutlined />
                                            </Tooltip>
                                        </span>
                                    </span>
                                    <span style={{marginRight: 30}}>
                                        时间区间：
                                        <DatePicker.RangePicker
                                            defaultValue={[
                                                moment(startTime),
                                                moment(endTime)
                                            ]}
                                            showTime
                                            format="YYYY/MM/DD HH:mm:ss"
                                            onChange={this.onRangeChange}
                                            ranges={{
                                                '近1天': [
                                                    moment(new Date().getTime() - 24 * 60 * 60 * 1000), moment()
                                                ],
                                                '近一周': [
                                                    moment(new Date().getTime() - 24 * 60 * 60 * 1000 * 7), moment()
                                                ],
                                                '近一月': [
                                                    moment(new Date().getTime() - 24 * 60 * 60 * 1000 * 30), moment()
                                                ],
                                            }}
                                        />
                                    </span>
                                    <Charts data={data} />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default BMCDetailPage;

