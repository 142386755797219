import React from 'react';
import { Tabs, Breadcrumb } from 'antd';
import BMCDetail from '../../components/BMC/detail';
import BMCCoreList from '../../components/BMC/core';
import { util } from '../../_helpers/util';

// import { getUserInfo } from '../../api';
import './index.less';
const { TabPane } = Tabs;
class BMCDetailPage extends React.Component {
    state = {
        changed: '0'
    };
    bmcId = '';
    type = '';

    async componentWillMount() {
        this.bmcId = util.getQueryString('bmcId');
        this.type = util.getQueryString('type');
        this.setState({
            changed: this.type
        });
    }
    callback = (key) => {
        this.setState({
            changed: key
        });
    }
    render() {
        const cssPre = 'device';
        const { changed } = this.state;
        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-header`}>
                    <h4 className='title'>BMC详情页</h4>
                    <Breadcrumb>
                        <Breadcrumb.Item>BMC管理</Breadcrumb.Item>
                        <Breadcrumb.Item>BMC详情页</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className={`${cssPre}-content`}>
                    <Tabs activeKey={changed} onChange={this.callback} style={{ margin: 10 }}>
                        <TabPane tab='BMC概况' key='0' >
                            <BMCDetail bmcId={this.bmcId}></BMCDetail>
                        </TabPane>
                        <TabPane tab='核心板列表' key={'1'}>
                            <BMCCoreList bmcId={this.bmcId}></BMCCoreList>
                        </TabPane>

                    </Tabs>
                </div>
            </div>
        );
    }
}

export default BMCDetailPage;

