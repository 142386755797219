import React from 'react';
import {
    Button, Input, Select, Table, DatePicker, Tag, message, Modal, Row, Col
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAlarmList, getCoreAlarmList } from '../../api';
import ColumnSetting from '../../components/ColumnSetting';
import moment from 'moment';
// import { util } from '../../_helpers/util';
const { Option } = Select;
const { RangePicker } = DatePicker;

let columns = [];
let buttonArr = [];
let yesterdayStart = moment(new Date((new Date().getTime() - 24 * 1 * 60 * 60 * 1000 + 1)));
let yesterdayEnd = moment(new Date().getTime());
class DevicePage extends React.Component {
    state = {
        machine: '',
        loading: false,
        selectedRowKeys: [], // 选择的列表项
        selectedRows: [], // 选择项数据数组
        monitorList: [], // 预警列表
        processIndex: null, // 核心板编号
        errorTimeFrom: yesterdayStart,
        errorTimeTo: yesterdayEnd,
        alarmLevel: null,
        bmcNumber: null,
        essorIp: null,
        dates: [],
        hackValue: '',
        value: '',
        pageSize: 10,
        pageNum: 1,
        total: 0,
        column: [],
        alarmType: null,
        confirmLoading: false,
        showTableLoading: false,
        showSearchLoading: false,
        modalTitle: '',
        modalContent: '',
        otherContent: '',
        showModal: false,
        handleOk: () => {
        },
        handleCancel: () => {
        }
    };

    componentDidMount() {
        let level = this.props.level;
        let alarmLevel = 2;
        if (level) {
            alarmLevel = level;
        }
        this.setState({
            alarmLevel
        }, () => {
            this.fetchAlarmList();
            this.init();
        });

    }

    componentWillReceiveProps(nextProps) {
        // this.setState({
        //     processIndex: '',
        //     alarmType: '',
        //     alarmLevel: '',
        //     pageSize: 10,
        //     pageNum: 1
        // }, () => {
        //     this.fetchAlarmList();
        // });
    }

    init = () => {
        let treeList = JSON.parse(localStorage.getItem('permitTree'));
        let buttonList = [];
        let _this = this;
        treeList && treeList.map((v, i) => {
            v.childMenuList && v.childMenuList.map((m, j) => {
                if (m.menuCode === 'device_list') {
                    buttonList = m.childMenuList;
                }
            });
        });
        buttonArr = buttonList && buttonList.map(v => {
            return v.menuCode;
        });
        const ForMatStatus = (props) => {
            const { status } = props;
            let maps = {
                'BMC断电': 'volcano',
                'BMC电源异常': 'volcano',
                'BMC风扇异常': 'volcano',
                '核心板断电': 'volcano',
                '核心板无法获取iP': 'volcano',
                '核心板未在位': 'volcano',
                '传感器异常': 'volcano',
                '严重': '#F59A23',
                '紧急': '#D9001B',
                '正常': 'rgb(22, 155, 213)',
                '轻微': '#70B603'
            };
            return <Tag color={maps[status]}>
                {status}</Tag>;
        };
        columns = [{
            title: '序号',
            dataIndex: 'sid',
            align: 'center',
            fixed: 'left'
        }, {
            title: 'BMC编号',
            key: 'bmcNumber',
            dataIndex: 'bmcNumber',
            align: 'center',
        }, {
            title: '警告类型',
            key: 'alarmType',
            dataIndex: 'alarmType',
            align: 'center',
            render: function (text, record, index) {
                let map;
                if (_this.props.type === 'core') {
                    map = ['正常', '核心板断电', '核心板未在位'];
                } else {
                    map = ['正常', 'BMC断电', 'BMC电源异常', 'BMC风扇异常', '核心板断电', '核心板无法获取iP', '核心板未在位', '传感器异常'];
                }
                return <ForMatStatus style={{ marginBottom: 5 }} status={map[text]} />;
            }
        }, {
            title: '状态',
            dataIndex: 'alarmLevel',
            align: 'center',
            render: function (text, record, index) {
                let map = ['正常', '轻微', '严重', '紧急'];
                return <ForMatStatus status={map[text]} />;
            }
        }, {
            title: '第一次警告时间',
            dataIndex: 'errorBeginTime',
            align: 'center',
            render: function (text) {
                return moment(text).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: '警告时长',
            dataIndex: 'errorTime',
            align: 'center'
        },
        {
            title: '描述',
            key: 'desc',
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <div>
                    <a onClick={this.getAlarmDetail.bind(this, text)}>查看</a>
                </div >
            )
        }];
        if (this.props.type === 'core') {
            columns.splice(2, 0, {
                title: '核心板卡位',
                dataIndex: 'processIndex',
                align: 'center'
            });
        }
        this.setState({
            column: columns
        });
    };
    fetchAlarmList = async () => {
        const { processIndex, errorTimeFrom, alarmLevel, errorTimeTo,
            bmcNumber, alarmType, pageNum, pageSize } = this.state;
        let params = {
            processIndex,
            errorTimeFrom: new Date(errorTimeFrom.valueOf()),
            errorTimeTo: new Date(errorTimeTo.valueOf()),
            alarmLevel,
            alarmType,
            bmcNumber,
            pageNum,
            pageSize
        };
        let res;
        this.setState({
            showTableLoading: true
        });
        try {
            if (this.props.type === 'core') {
                res = await getCoreAlarmList(params);
            } else {
                res = await getAlarmList(params);
            }

            this.setState({
                monitorList: res.data,
                total: res.total
            });
        } catch (e) {
        }
        this.setState({
            showSearchLoading: false,
            showTableLoading: false
        });

    };
    getAlarmDetail = (text) => {
        this.setState({
            showModal: true,
            modalTitle: '预警详情',
            modalContent: <div style={{ height: 300, overflow: 'auto' }}>
                <Row className='alarm-item'>
                    <Col span={8}>IP</Col>
                    <Col span={16}>警告类型</Col>
                </Row>
                {
                    text.desc && text.desc.map((v, i) => {
                        let arr = v && v.split(',');
                        return <Row className='alarm-item'>
                            <Col span={8}> {arr[0]}</Col>
                            <Col span={16}>{arr[1]}</Col>
                        </Row>;

                    })
                }
            </div>,
            handleOk: async () => {

                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };

    // exportDevice = async () => {
    //     const {
    //         processIndex, errorTimeFrom, bmcNumber, errorTimeTo, alarmType
    //     } = this.state;
    //     let params = {
    //         processIndex,
    //         errorTimeFrom,
    //         bmcNumber,
    //         errorTimeTo,
    //         alarmType
    //     };
    //     try {
    //         let data = await exportDeviceList(params);
    //         let blob = new Blob([data]);
    //         const excelName = '设备列表.xlsx';
    //         util.downloadBolb(blob, excelName);
    //     } catch (e) {

    //     }
    // };


    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
        this.setState({ selectedRowKeys, selectedRows });
    };

    onSearch = async (key) => {

    };
    onPageChange = (page, pageSize) => {
        this.setState({
            pageNum: page,
            pageSize
        }, () => {
            this.fetchAlarmList();
        });
    };


    // 重启核心板
    BMCArmReset = (record) => {
        let array = [];
        this.setState({
            showModal: true,
            modalTitle: '核心板断电重启重启',
            modalContent: <p>核心板断电后可能导致设备无法正常使用，是否继续对核心板断电？</p>,
            handleOk: async () => {
                if (record.type && record.type === 'all') {
                    const { selectedRows } = this.state;
                    selectedRows.map((item, i) => {
                        let temp = {};
                        temp.processIndex = item.processIndex;
                        temp.manageIp = item.manageIp;
                        array.push(temp);
                    });

                } else {
                    const { processIndex, manageIp } = record;
                    let temp = {};
                    temp.processIndex = processIndex;
                    temp.manageIp = manageIp;
                    array.push(temp);
                }

                try {
                    await processorReset(array);
                    message.success('核心板已断电');
                } catch (error) {
                }
                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };


    // 搜索项变化触发
    searchInfoChange = (type, e) => {
        let { processIndex, errorTimeFrom, errorTimeTo, alarmType, alarmLevel, bmcNumber } = this.state;
        let value;
        if (e) {
            value = e.target ? (e.target.value).trim() : e;
        } else {
            value = e;
        }
        ;
        switch (type) {
            case 'processIndex': {
                processIndex = value;
                break;

            }
            case 'alarmLevel': {
                alarmLevel = value;
                break;

            }
            case 'alarmType': {
                alarmType = value;
                break;

            }
            case 'bmcNumber': {
                bmcNumber = value;
                break;

            }
            case 'time': {
                if (!e) {
                    errorTimeFrom = value;
                    errorTimeTo = value;
                } else {
                    errorTimeFrom = value[0];
                    errorTimeTo = value[1];
                }
            }

        }

        this.setState({
            pageNum: 1,
            processIndex,
            alarmLevel,
            errorTimeFrom,
            errorTimeTo,
            alarmType,
            bmcNumber
        }, () => {
            if (type === 'alarmType' || type === 'alarmLevel') {
                this.search();
            }
        });
    };

    search = () => {
        this.setState({
            pageNum: 1,
            selectedRowKeys: [],
            selectedRows: []
        }, () => {
            this.fetchAlarmList();
        });

    };

    render() {
        const cssPre = 'device';
        const {
            loading, otherContent, errorTimeTo, selectedRowKeys, confirmLoading, monitorList, bmcNumber,
            showTableLoading, showSearchLoading, pageNum, errorTimeFrom, alarmLevel, column, dates,
            total, showModal, handleCancel, handleOk, modalContent, modalTitle, processIndex,
            alarmType, pageSize, } = this.state;
        const { type } = this.props;
        // const disabledDate = (current) => {
        //     return current && current < moment('2020-01-01').endOf('day');
        // };
        const disabledDate = current => {
            if (!dates || dates.length === 0) {
                return false;
            }
            const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
            return tooEarly || tooLate;
        };
        const onOpenChange = open => {
            if (open) {
                this.setState({
                    hackValue: [],
                    dates: []
                });
            } else {
                this.setState({
                    hackValue: undefined
                });
            }
        };
        monitorList && monitorList.map((v, i) => {
            v.sid = (pageNum - 1) * pageSize + i + 1;
            v.key = i;
        });
        const culumnsData = columns.map((item) => {
            return item.title;
        });
        const culumnsSelectData = column.map((item) => {
            return item.title;
        });
        const columnsChange = (list) => {
            let temp = [];
            temp = columns.filter((item) => {
                return list.indexOf(item.title) !== -1;
            });
            this.setState({
                column: temp
            });
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-content`}>
                    <div className=''>
                        {/* {buttonArr.indexOf('device_list_search') > -1 && */}
                        <div className='search'>
                            <Input.Group compact className='search-wrapper'>
                                <div className='left'>
                                    <div className='search-ida'>
                                        <span className='title'>BMC编号：</span>
                                        <Input placeholder='输入BMC编号' allowClear
                                            className='search-device-name'
                                            value={bmcNumber}
                                            style={{ width: 150, marginBottom: 20 }}
                                            onChange={this.searchInfoChange.bind(this, 'bmcNumber')} />
                                    </div>
                                    {type === 'core' &&
                                        <div className='search-ida'>
                                            <span className='title'>核心卡位：</span>
                                            <Input placeholder='输入核心卡位' allowClear
                                                className='search-device-name'
                                                value={processIndex}
                                                style={{ width: 150, marginBottom: 20 }}
                                                onChange={this.searchInfoChange.bind(this, 'processIndex')} />
                                        </div>
                                    }

                                    <div className='search-ida'>
                                        <span className='title'>时间：</span>
                                        <RangePicker
                                            showTime
                                            // picker="month"
                                            onOpenChange={onOpenChange}
                                            onCalendarChange={val => { this.setState({ dates: val }); }}
                                            disabledDate={disabledDate}
                                            value={[errorTimeFrom, errorTimeTo]}
                                            onChange={this.searchInfoChange.bind(this, 'time')}
                                        />
                                    </div>
                                    <div className='search-ida'>
                                        <span className='title'>警告类型：</span>
                                        <Select onChange={
                                            this.searchInfoChange.bind(this, 'alarmType')}
                                            showSearch
                                            style={{ width: 150, marginBottom: 20 }}
                                            value={alarmType}
                                            placeholder='请选择警告类型'>
                                            <Option value={null}>所有</Option>
                                            {/* <Option value={0}>正常</Option> */}
                                            {type !== 'core' &&
                                                <>
                                                    <Option value={1}>BMC断电</Option>
                                                    <Option value={2}>BMC电源异常</Option>
                                                    <Option value={3}>BMC风扇异常</Option>
                                                </>
                                            }
                                            <Option value={4}>核心板断电</Option>
                                            <Option value={5}>核心板无法获取iP</Option>
                                            <Option value={6}>核心板未在位</Option>
                                            {type !== 'core' &&
                                                <Option value={7}>传感器异常</Option>
                                            }
                                        </Select>
                                    </div>
                                    <div className='search-ida'>
                                        <span className='title'>状态：</span>
                                        <Select onChange={
                                            this.searchInfoChange.bind(this, 'alarmLevel')}
                                            showSearch
                                            style={{ width: 150 }}
                                            value={alarmLevel}
                                            placeholder='请选择状态'>
                                            <Option value={null}>所有</Option>
                                            <Option value={1} disabled={type === 'core'}>轻微</Option>
                                            <Option value={2}>严重</Option>
                                            <Option value={3} disabled={type === 'core'}>紧急</Option>
                                        </Select>
                                    </div>
                                    <Button type='primary' icon={<SearchOutlined />} loading={showSearchLoading}
                                        onClick={this.search}>查询</Button>
                                </div>
                                <div className='right'></div>

                            </Input.Group>
                        </div>
                        {/* } */}
                        <div className='operation'>
                            <div className='left'>

                                {buttonArr.indexOf('group_transfer') > -1 &&

                                    <Button type='primary' onClick={this.BMCArmReset.bind(this, { type: 'all' })}
                                        disabled={!hasSelected} loading={loading}>
                                        断电重启
                                    </Button>
                                }
                                {/* {buttonArr.indexOf('device_list_export') > -1 &&
                                    <Button type='primary' style={{ marginLeft: 10 }} onClick={this.exportDevice}>
                                        导出
                                    </Button>
                                } */}
                                <span style={{ marginLeft: 8, width: '82px' }}>
                                    {hasSelected ? `已选择${selectedRowKeys.length}项` : ''}
                                </span>
                            </div>
                            <div className='right'>
                                <ColumnSetting options={culumnsData} selectedRowKeys={culumnsSelectData}
                                    onChange={columnsChange}></ColumnSetting>
                            </div>
                        </div>
                    </div>

                    <div className='result'>
                        <Table
                            rowSelection={rowSelection}
                            columns={column}
                            dataSource={monitorList}
                            // scroll={{ x: 1300 }} sticky
                            loading={showTableLoading}
                            showSorterTooltip={false}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                current: pageNum,
                                pageSize,
                                showTotal: total => {
                                    return `总计${total}项`;
                                },
                                onChange: this.onPageChange,
                                total: total
                            }}
                        />
                    </div>
                </div>
                <Modal title={modalTitle} visible={showModal} onOk={handleOk} onCancel={handleCancel} okText='确认'
                    cancelText='取消'
                    destroyOnClose={true}
                    confirmLoading={confirmLoading}>
                    {modalContent}
                    {otherContent}
                </Modal>
            </div>
        );
    }
}

export default DevicePage;

