import React from 'react';
import { Row, Col, Breadcrumb, Select, Modal, Input, Button, Table, message, Space, Tag } from 'antd';
import { getGroupList, getUserList, addUser, editUser, deleteUser } from '../../api';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;
import ColumnSetting from '../../components/ColumnSetting';

import './index.less';

let buttonArr = [];
let columns = [];
class UserManagePage extends React.Component {
	state = {
		groupList: [], // 分组角色列表
		userList: [], // 用户列表
		realName: '',
		mobile: '',
		groupId: '',
		pageSize: 10,
		total: 0,
		column: [],
		showSearchLoading: false,
		showTableLoading: false,
		pageNo: 1,
		confirmLoading: false,
		modalTitle: '',
		modalContent: '',
		showModal: false,
		handleOk: () => { },
		handleCancel: () => { }
	};

	async componentDidMount() {
		this.fetchGroupList();
		this.fetchUserList();
		this.init();
	}
	init = () => {
		let treeList = JSON.parse(localStorage.getItem('permitTree'));

		let buttonList = [];

		treeList && treeList.map((v, i) => {
			v.childMenuList && v.childMenuList.map((m, j) => {
				if (m.menuCode === 'user_list') {
					buttonList = m.childMenuList;
				}
			});
		});
		buttonArr = buttonList && buttonList.map(v => {
			return v.menuCode;
		});
		const ForMatStatus = (props) => {
			const { text } = props;
			let maps = ['PM', '研发', '商务', '项目管理'];
			let colors = ['magenta', 'red', 'orange', 'cyan', 'blue', 'geekblue', 'purple'];
			return <Tag color={colors[maps.indexOf(text)]} >{text}</Tag>;
		};
		columns = [
			{
				title: '用户名',
				dataIndex: 'username',
				align: 'center',
				render: (text, record) => (<a onClick={this.lookUserDetail.bind(this, record)}>{text}</a>)

			},
			{
				title: '姓名',
				dataIndex: 'realName',
				align: 'center',
			},
			{
				title: '手机号',
				dataIndex: 'mobile',
				align: 'center',
			},
			{
				title: '角色',
				key: 'groupName',
				dataIndex: 'groupName',
				align: 'center',
				render: text => (<ForMatStatus text={text} type='bind' />
				),
				sorter: (a, b) => a.groupName - b.groupName,
				sortDirections: ['descend', 'ascend'],
			}
		];
		if (buttonArr.indexOf('user_update') > -1 || buttonArr.indexOf('user_delete') > -1) {
			columns.push(
				{
					title: '操作',
					key: 'action',
					align: 'center',
					render: (text, record) => (
						<Space size='middle'>
							{buttonArr.indexOf('user_update') > -1 &&
								<Tag color='blue' className='pointer' icon={<EditOutlined />}
									onClick={this.modifyUser.bind(this, 'edit', record)}>编辑</Tag>
							}
							{buttonArr.indexOf('user_delete') > -1 &&
								<Tag color='volcano' className='pointer' icon={<DeleteOutlined />}
									onClick={this.deleteUser.bind(this, record)}>删除</Tag>
							}
						</Space>
					),
				});
		}
		this.setState({
			column: columns
		});

	}
	fetchGroupList = async () => {
		const res = await getGroupList();
		this.setState({
			groupList: res
		});
	}
	fetchUserList = async () => {
		const { realName, pageSize, pageNo, mobile, groupId } = this.state;
		let params = {
			realName,
			mobile,
			groupId,
			pageSize,
			pageNo
		};
		this.setState({
			showTableLoading: true
		});
		try {
			const res = await getUserList(params);
			this.setState({
				userList: res.data,
				total: res.total
			});
		} catch (e) {

		}
		this.setState({
			showSearchLoading: false,
			showTableLoading: false
		});

	}
	// 搜索项变化触发
	searchInfoChange = (type, e) => {
		let { realName, mobile, groupId } = this.state;
		const value = e.target ? (e.target.value).trim() : e;

		switch (type) {
			case 'realName': {
				realName = value;
				break;

			}
			case 'mobile': {
				mobile = value;
				break;

			}
			case 'groupId': {
				groupId = value;
				break;

			}
		}
		this.setState({
			realName,
			mobile,
			groupId
		}, () => {
			if (type === 'groupId') {
				this.fetchUserList();
			}
		});
	}
	search = () => {
		this.fetchUserList();
	}
	onPageChange = (page, pageSize) => {
		this.setState({
			pageNo: page,
			pageSize
		}, () => {
			this.fetchUserList();
		});
	}
	lookUserDetail = (user) => {
		// const { groupList } = this.state;
		let realName = user.realName || '';
		let mobile = user.mobile || '';
		let email = user.email || '';
		let groupName = user.groupName || '';

		Modal.warning({
			title: '查看用户',
			okText: '确定',
			icon: null,
			content: (
				<>

					<Row align='middle'>
						<Col span={8} className='label'>用户姓名:</Col>
						<Col span={14} className='value'>
							<span>{realName}</span>
						</Col>
					</Row>
					<Row align='middle'>
						<Col span={8} className='label'>用户手机号:</Col>
						<Col span={16} className='value'>
							<span>{mobile}</span>
						</Col>
					</Row>
					<Row align='middle'>
						<Col span={8} className='label'>用户邮箱:</Col>
						<Col span={16} className='value'>
							<span>{email}</span>
						</Col>
					</Row>
					<Row align='middle'>
						<Col span={8} className='label'>用户角色:</Col>
						<Col span={16} className='value'>
							<span>{groupName}</span>
						</Col>
					</Row>

				</>)
		});
	}
	modifyUser = (type, user) => {
		const { groupList } = this.state;
		let _this = this;
		let realName = user.realName || '';
		let mobile = user.mobile || '';
		let email = user.email || '';
		let groupId = user.groupId || '';
		let userInfoChange = (type, e) => {
			const value = e.target ? (e.target.value).trim() : e;
			switch (type) {
				case 'realName': {
					realName = value;
					break;
				}
				case 'mobile': {
					mobile = value;
					break;
				}
				case 'email': {
					email = value;
					break;
				}
				case 'groupId': {
					groupId = value;
					break;
				}
			};
		};
		this.setState({
			showModal: true,
			modalTitle: type === 'add' ? '新增用户' : '编辑用户',
			modalContent: (
				<>

					<Row style={{ marginBottom: 20 }} align='middle'>
						<Col span={4} className='label'>用户姓名</Col>
						<Col span={16} className='value'>
							<Input placeholder='输入员工姓名' allowClear className='search-device-name'
								defaultValue={realName} onChange={userInfoChange.bind(this, 'realName')} />
						</Col>
					</Row>
					<Row style={{ marginBottom: 20 }} align='middle'>
						<Col span={4} className='label'>用户手机号</Col>
						<Col span={16} className='value'>
							<Input placeholder='输入员工手机号' allowClear className='search-device-name'
								defaultValue={mobile} onChange={userInfoChange.bind(this, 'mobile')} />
						</Col>
					</Row>
					<Row style={{ marginBottom: 20 }} align='middle'>
						<Col span={4} className='label'>用户邮箱</Col>
						<Col span={16} className='value'>
							<Input placeholder='输入员工邮箱' allowClear className='search-device-name'
								defaultValue={email} onChange={userInfoChange.bind(this, 'email')} />
						</Col>
					</Row>
					<Row style={{ marginBottom: 20 }} align='middle'>
						<Col span={4} className='label'>用户角色</Col>
						<Col span={16} className='value'>
							<Select onChange={userInfoChange.bind(this, 'groupId')}
								showSearch
								defaultValue={groupId}
								style={{ width: 200 }}
								placeholder='请选择用户角色'>
								{
									groupList.map((item, index) => {
										return <Option value={item.groupId}>{item.groupName}</Option>;
									})
								}

							</Select>
						</Col>
					</Row>

				</>),
			handleOk: async () => {
				let myreg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				let strRegex = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
				if (realName.length === 0) {
					message.error('请填写用户姓名');
					return;
				}
				if (type === 'add') {
					if (mobile.length === 0) {
						message.error('请填写用户手机号');
						return;
					} else if (!myreg.test(mobile)) {
						message.error('用户手机号格式不正确');
						return;
					}
					if (email.length === 0) {
						message.error('请填写用户邮箱');
						return;
					} else if (!strRegex.test(email)) {
						message.error('用户邮箱格式不正确');
						return;
					}
				}
				if (type === 'edit') {
					if (!((mobile.indexOf('****') > -1 && user.mobile === mobile) || mobile.length === 0)) {
						if (!myreg.test(mobile)) {
							message.error('用户手机号格式不正确');
							return;
						}
					}
				}
				if (groupId === -1) {
					message.error('请选择用户角色');
					return;
				}
				let params = null;
				if (type === 'add') {
					params = {
						realName,
						mobile,
						email,
						groupId
					};
				} else if (type === 'edit') {
					if (mobile.indexOf('****') > -1 || mobile.length === 0) {
						params = {
							realName,
							groupId
						};
					} else {
						params = {
							realName,
							mobile,
							groupId
						};
					}
				}
				this.setState({
					confirmLoading: true,
				});
				if (type === 'add') {
					try {
						await addUser(params);
						message.success('新增用户成功');
						_this.fetchUserList();
					} catch (error) {
					}
				} else if (type === 'edit') {
					params.userId = user.userId;
					try {
						await editUser(params);
						message.success('修改用户成功');
						_this.fetchUserList();
					} catch (error) {
					}
				}

				this.setState({
					confirmLoading: false,
					showModal: false
				});
			},
			handleCancel: () => {
				this.setState({
					showModal: false
				});
			}
		});
	}

	deleteUser = (user) => {
		const { userId, username } = user;
		let params = {
			userId
		};
		this.setState({
			showModal: true,
			modalTitle: '删除用户',
			modalContent: (
				<>
					<p>是否确定删除该用户？</p>
					<Row className='padcode' style={{ marginBottom: 10 }}>
						<Col span={3} className='label'>用户名:</Col>
						<Col span={16} className='value'>{username}</Col>
					</Row>
				</>),
			handleOk: async () => {

				try {
					await deleteUser(params);
					message.success('删除用户成功');
					this.fetchUserList();
					this.setState({
						showModal: false
					});
				} catch (error) {

				}

			},
			handleCancel: () => {
				this.setState({
					showModal: false
				});
			}
		});
	}

	render() {
		const cssPre = 'config';
		const { userList, groupList, modalTitle, showModal, handleOk, handleCancel, confirmLoading,
			modalContent, showTableLoading, showSearchLoading, total, pageNo, column } = this.state;
		const culumnsData = columns.map((item) => {
			return item.title;
		});
		const culumnsSelectData = column.map((item) => {
			return item.title;
		});
		const columnsChange = (list) => {
			let temp = [];
			temp = columns.filter((item) => {
				return list.indexOf(item.title) !== -1;
			});
			this.setState({
				column: temp
			});
		};

		return (
			<div className={`${cssPre}`}>
				<div className={`${cssPre}-header`}>
					<h4 className='title'>用户管理</h4>
					<Breadcrumb>
						<Breadcrumb.Item>系统设置</Breadcrumb.Item>
						<Breadcrumb.Item>用户管理</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className={`${cssPre}-content`}>
					<div className='block'>
						{buttonArr.indexOf('user_list_search') > -1 &&

							<div className='search'>
								<Input.Group compact className='search-wrapper' >
									<div className='search-ida'>
										<span>用户姓名：</span>
										<Input placeholder='输入用户姓名' allowClear className='search-device-name'
											onChange={this.searchInfoChange.bind(this, 'realName')} />
									</div>
									<div className='search-ida'>
										<span>用户手机号：</span>
										<Input placeholder='输入用户手机号' allowClear className='search-device-name'
											onChange={this.searchInfoChange.bind(this, 'mobile')} />
									</div>
									<div className='search-ida'>
										<span>用户角色：</span>
										<Select onChange={this.searchInfoChange.bind(this, 'groupId')}
											showSearch
											style={{ width: 200 }}
											defaultValue=''
											placeholder='请选择用户角色'>
											<Option value=''>所有</Option>

											{
												groupList.map((item, index) => {
													return <Option value={item.groupId}>{item.groupName}</Option>;
												})
											}
										</Select>
									</div>
									<Button type='primary' icon={<SearchOutlined />} loading={showSearchLoading}
										onClick={this.search}>查询</Button>
								</Input.Group>
							</div>
						}

						<div className='operation'>
							<div className='left'>
								{buttonArr.indexOf('user_add') > -1 &&
									<Button type='primary' onClick={this.modifyUser.bind(this, 'add')}>
										新增用户
									</Button>
								}
							</div>
							<div className='right'>
								<ColumnSetting options={culumnsData} selectedRowKeys={culumnsSelectData}
									onChange={columnsChange}></ColumnSetting>
							</div>
						</div>
					</div>
					<div className='result'>
						<Table columns={column} dataSource={userList} loading={showTableLoading}
							showSorterTooltip={false}
							pagination={{
								showSizeChanger: true,
								showQuickJumper: true,
								current: pageNo,
								showTotal: total => { return `总计${total}项`; },
								onChange: this.onPageChange,
								total: total
							}}

						/>

					</div>
				</div>
				<Modal title={modalTitle} visible={showModal} onOk={handleOk} onCancel={handleCancel} okText='确认'
					cancelText='取消'
					destroyOnClose={true}
					confirmLoading={confirmLoading}>
					{modalContent}
				</Modal>
			</div>
		);
	}
}

export default UserManagePage;

