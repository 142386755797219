import { request } from '../_helpers';
import { config } from '../config';

let baseURL = config.apiUrl;
// BMC预警列表
export async function getAlarmList(params) {
    return request.post(`${baseURL}/acb/manage/bmc/monitor/bmc/list/page`, params);
};
// 核心板预警列表
export async function getCoreAlarmList(params) {
    return request.post(`${baseURL}/acb/manage/bmc/monitor/core/list/page`, params);
};
// 预警详情
export async function getMonitorDetail(params) {
    return request.post(`${baseURL}/acb/manage/bmc/alarm/detail`, params);
};
// 历史预警数据统计
export async function getMonitorStatistics(params) {
    return request.post(`${baseURL}/acb/manage/bmc/alarm/statistics`, params);
};
// 设备列表导出
export async function exportDeviceList(params) {
    return request.post(`${baseURL}/aca/manage/v2/device/list/export`, params, {
        responseType: 'blob'
    });
};
