import { Route } from 'react-router-dom';
import HomePage from '../pages/Home/index';
import BMCPage from '../pages/BMC/index';
import BMCDetailPage from '../pages/BMC/detail';
import UserManagePage from '../pages/Config/user';
import AuthConfig from '../pages/Config/auth';
import CorePage from '../pages/Core';
import MonitorPage from '../pages/Monitor';
import LogManagePage from '../pages/Logs/manage';
import LogDevicePage from '../pages/Logs/device';
const Routes = () => (
    <>
        <Route path='/console/home' component={HomePage} />
        <Route path='/console/bmc/list' component={BMCPage} />
        <Route path='/console/bmc/detail' component={BMCDetailPage} />
        <Route path='/console/user/list' component={UserManagePage} />
        <Route path='/console/auth/config' component={AuthConfig} />
        <Route path='/console/core/list' component={CorePage} />
        <Route path='/console/monitor/list' component={MonitorPage} />
        <Route path='/console/log/manage' component={LogManagePage} />
        <Route path='/console/log/device' component={LogDevicePage} />
    </>
);
export default Routes;
