import React from 'react';
import { Input, Form, Button, message, Row, Col, Tabs, Statistic, Alert, Steps } from 'antd';
import './index.less';
import { history } from '../../_helpers';
import { connect } from 'react-redux';
import { getSMSCode, getSMSCodeByUser, verifySMSCode, updatePassword } from '../../api';

import { userActions, alertActions } from '../../_actions';

import {
	UserOutlined,
	LockOutlined
} from '@ant-design/icons';

const { TabPane } = Tabs;
const { Countdown } = Statistic;
const { Step } = Steps;

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		history.listen((location, action) => {
			this.props.clearAlerts();
		});
		// 重置登录状态
		this.props.logout();
		this.state = {
			shake: false,
			mobile: '',
			time: 5,
			username: '',
			countdown: false,
			resetCountdown: false,
			current: 0,
			verifyCode: '',
			type: 'login',
			deadline: Date.now() + 60000, // 验证码倒计时
			resetDeadline: Date.now() + 5000, // 重置密码成功倒计时
			currentMode: 'account'
		};

	}

	changeValidType = (key) => {
		console.log(key);
		this.setState({
			currentMode: key
		});
		this.props.clearAlerts();

	}
	getVCode = async () => {
		const { mobile } = this.state;
		if (mobile.length === 0) {
			message.error('请填写手机号');
			return;
		}
		if (!(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(mobile))) {
			message.error('手机号码格式错误');
			return false;
		}
		let params = {
			mobile
		};
		try {
			await getSMSCode(params);
			message.success('验证码发送成功!');
			this.setState({
				countdown: true,
				deadline: Date.now() + 60000
			});
		} catch (e) {

		}

	}
	getVCodeByUsername = async () => {
		const { username } = this.state;
		if (username.length === 0) {
			message.error('请输入用户名!');
			return;
		}
		let params = {
			userInfo: username
		};
		try {
			await getSMSCodeByUsername(params);
			message.success('验证码发送成功!');
			this.setState({
				countdown: true,
				deadline: Date.now() + 60000
			});
		} catch (e) {

		}

	}
	getVCodeByUser = async () => {
		const { username } = this.state;
		if (username.length === 0) {
			message.error('请填写用户名');
			return;
		}
		let params = {
			username,
			clientId: 0
		};
		try {
			const phone = await getSMSCodeByUser(params);
			if (phone) {
				message.success(`验证码已发送至${phone}！`);
			}
			this.setState({
				countdown: true,
				deadline: Date.now() + 60000
			});
		} catch (e) {

		}

	}
	checkVCode = async (values) => {
		const { mobile, code: verifyCode } = values;
		let params = {
			mobile,
			verifyCode
		};
		try {
			await verifySMSCode(params);
			this.setState({
				verifyCode
			});
			return true;
		} catch (e) {
			return false;
		}

	}
	onUserNameChange = (e) => {
		const { value } = e.target;
		this.setState({
			username: value.trim()
		});
	}
	setNewPassword = async (values) => {
		const { mobile, verifyCode } = this.state;
		const { password } = values;
		let params = {
			mobile,
			verifyCode,
			password
		};
		try {
			await updatePassword(params);
			return true;
		} catch (e) {
			return false;
		}
	}

	handlePhoneChange = (e) => {
		const { value } = e.target;
		this.setState({
			mobile: value.trim()
		});
	}
	onFinishFailed = () => {
		this.setState({
			shake: true
		});
	}
	onFinish = (values) => {
		const { type, currentMode } = this.state;
		if (type === 'login') {
			this.props.login(values, currentMode);
		} else if (type === 'changePassword') {
			this.resetPasswordSteps(values);

		}

	};
	startCountDown = () => {
		this.timer = setInterval(() => {
			this.leftTimer();
		}, 1000);
	}
	leftTimer = () => {
		let { time } = this.state;
		if (time > 0) {
			this.setState({
				time: --time
			});
		} else {
			this.backToLogin();
			clearInterval(this.timer);// 倒计时结束，清除倒计时
		}
	}
	resetPasswordSteps = (values) => {
		const { current } = this.state;
		// 验证手机号提交
		if (current === 0) {
			this.checkVCode(values)
				.then(
					result => {
						if (result) {
							this.setState({
								current: 1
							});
						}
					},
					error => {

					}
				);

		} else if (current === 1) { // 设置新密码提交
			this.setNewPassword(values)
				.then(
					result => {
						if (result) {
							this.setState({
								current: 2
							}, () => {
								this.startCountDown();
							});
						}
					},
					error => {

					}
				);
		} else { // 密码重置成功

		}
	}
	onCountDownFinish = () => {
		this.setState({
			countdown: false,
			deadline: Date.now() + 0
		});
	}
	onResetCountDownFinish = () => {
		this.setState({
			resetCountdown: false,
			resetDeadline: Date.now() + 0
		}, () => {
			this.backToLogin();
		});

	}
	changePassword = () => {
		this.setState({
			type: 'changePassword',
			mobile: ''
		});
	}
	backToLogin = () => {
		this.setState({
			type: 'login',
			mobile: '',
			current: 0,
			currentMode: 'account',
		});
	}
	render() {
		const { loggingIn, alert } = this.props;
		const { shake, countdown, time, deadline, type, current } = this.state;
		const cssPre = 'login';
		const isSMS = false;

		const selectAfter = (
			countdown ?
				<Countdown
					title=''
					className='countdown'
					value={deadline}
					onFinish={this.onCountDownFinish}
					format='s'
					suffix='s'
				/> : <span className='get-code' onClick={this.getVCode}>获取验证码</span>

		);
		const selectAfterByUser = (
			countdown ?
				<Countdown
					title=''
					className='countdown'
					value={deadline}
					onFinish={this.onCountDownFinish}
					format='s'
					suffix='s'
				/> : <span className='get-code' onClick={this.getVCodeByUser}>获取验证码</span>

		);
		const customDot = (dot, { status, index }) => (
			<>{dot}</>
		);
		return (
			<div className={`${cssPre}-content`}>
				<div className={`${cssPre}-header`}>
				</div>
				<Row className={`${cssPre}-box`}>
					<Col span={10} className={`${cssPre}-logo-box`} >
						<h2 className={`${cssPre}-title`}>ARM云BMC控制台</h2>
						<div className={`${cssPre}-logo`}></div>
					</Col>

					{
						type === 'login' &&
						<Col span={14} className={`${cssPre}-login-form`}>
							<Tabs defaultActiveKey='account'
								onChange={this.changeValidType}
								centered={true}
								size='large'
								className='login-tabs'>
								<TabPane tab='账号登录' key='account'>
									<Form
										name='basic'
										onFinish={this.onFinish}
										onFinishFailed={this.onFinishFailed}
										className='items'
									>
										<Form.Item
											name='username'
											hasFeedback
											rules={[
												{
													required: true,
													message: '请输入用户名',
												},
											]}
										>
											<Input className={`${cssPre}-account`}
												prefix={<UserOutlined className='site-form-item-icon' />}
												allowClear
												placeholder='请输入用户名'
												onChange={this.onUserNameChange}
											/>
										</Form.Item>
										<Form.Item
											name='password'
											hasFeedback
											rules={[
												{
													required: true,
													message: '请输入密码',
												},
											]}
										>
											<Input
												type='password'
												className={`${cssPre}-password`}
												allowClear
												prefix={<LockOutlined className='site-form-item-icon' />}
												placeholder='请输入密码'
											/>
										</Form.Item>

										{
											isSMS && (
												<Form.Item>
													<Row gutter={8}>
														<Col>
															<Form.Item
																name='sms_verify_code'
																rules={[
																	{
																		required: true,
																		message: '请输入验证码',
																	},
																]}
															>
																<Input
																	// type='password'
																	className={`${cssPre}-sms-code`}
																	maxLength={6}
																	// prefix={
																	// <LockOutlined
																	// 	className='site-form-item-icon'
																	// />
																	// }
																	addonAfter={selectAfterByUser}
																	placeholder='请输入验证码'
																	// onChange={this.handleSmsCodeChange}
																	autoComplete="off"
																/>
															</Form.Item>
														</Col>
													</Row>
												</Form.Item>
											)
										}

										{/* <Form.Item name='remember' value='checked' noStyle>
											<Checkbox>记住登录状态</Checkbox>
										</Form.Item> */}
										<Form.Item >
											<Button
												htmlType='submit'
												type='primary'
												loading={loggingIn}
												className={['login-login-btn',
													shake ? 'swing-enter swing-enter-active' : ''].join(' ')}
											>
												登录
											</Button>
										</Form.Item>
										<div className={`${cssPre}-others`}>
											<div>
												{/* 还没有账号？<span className='blue'>立即注册</span> */}
											</div>
											<div className='blue pointer' onClick={this.changePassword}>忘记登录密码？</div>
										</div>
										{alert.message &&
											<Alert message={alert.message} type={alert.type} showIcon />
										}
									</Form>
								</TabPane>

							</Tabs>
						</Col>
					}
					{type === 'changePassword' &&
						<Col span={14} className={`${cssPre}-reset`}>
							<h3>重置密码</h3>
							<Steps size='small' current={current} progressDot={customDot} className='steps'>
								<Step title='验证手机号' />
								<Step title='设置新密码' />
								<Step title='密码重置成功' />
							</Steps>

							{current === 0 &&
								<Form
									name='basic'
									onFinish={this.onFinish}
									onFinishFailed={this.onFinishFailed}
									className='items'
								>
									<Form.Item
										name='mobile'
										hasFeedback
										rules={[
											{
												required: true,
												message: '请输入手机号码',
											},
											{
												pattern: /^[^\s]*$/,
												message: '禁止输入空格',
											},
											{
												pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
												message: '请输入正确的手机号'
											}
										]}
									>
										<Input className={`${cssPre}-account`}
											prefix={<UserOutlined className='site-form-item-icon' />}
											allowClear
											placeholder='请输入手机号码'
											onChange={this.handlePhoneChange}
										/>
									</Form.Item>
									<Form.Item
										name='code'
										rules={[
											{
												required: true,
												message: '请输入验证码',
											},
										]}
									>
										<Input
											type='password'
											className={`${cssPre}-password`}
											allowClear
											maxLength={6}
											prefix={<LockOutlined className='site-form-item-icon' />}
											addonAfter={selectAfter}
											placeholder='请输入验证码'
										/>
									</Form.Item>
									<Form.Item >
										<Button
											htmlType='submit'
											type='primary'
											className={['login-login-btn',
												shake ? 'swing-enter swing-enter-active' : ''].join(' ')}
										>
											下一步
										</Button>
									</Form.Item>
									<div className={`${cssPre}-others`}>
										<div className='blue pointer' onClick={this.backToLogin}>返回登录页</div>
									</div>
								</Form>

							}
							{current === 1 &&
								<Form
									name='basic'
									onFinish={this.onFinish}
									onFinishFailed={this.onFinishFailed}
									className='items'
								>
									<Form.Item
										name='password'
										hasFeedback
										rules={[
											{
												required: true,
												message: '请输入密码',
											},
											{
												pattern: /(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}/,
												message: '请设置8-20位登录密码(包含字母、数字)'
											}
										]}
									>
										<Input
											type='password'
											className={`${cssPre}-password`}
											allowClear
											minLength={8}
											maxLength={20}
											prefix={<LockOutlined className='site-form-item-icon' />}
											placeholder='请设置8-20位字母数字组合登录密码'
										/>

									</Form.Item>
									<Form.Item
										name='confirm'
										hasFeedback
										rules={[
											{
												required: true,
												message: '请确认输入密码',
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}

													return Promise.reject(new Error('两次输入密码不一致!'));
												},
											}),
										]}
									>
										<Input
											type='password'
											className={`${cssPre}-password`}
											allowClear
											minLength={6}
											maxLength={20}
											prefix={<LockOutlined className='site-form-item-icon' />}
											placeholder='请再次输入新的登录密码'
										/>
									</Form.Item>
									<Form.Item >
										<Button
											htmlType='submit'
											type='primary'
											className={['login-login-btn',
												shake ? 'swing-enter swing-enter-active' : ''].join(' ')}
										>
											确定
										</Button>
									</Form.Item>
								</Form>

							}

							{current === 2 &&
								<div className='success'>
									<h2>密码重置成功</h2>
									{time}秒后自动跳转至登录页
									<Button
										onClick={this.backToLogin}
										style={{ marginTop: 30, width: 341, height: 40 }}
										type='primary'
									>
										立即登录
									</Button>
								</div>
							}



						</Col>
					}


				</Row>
			</div>
		);
	}
}
function mapState(state) {
	const { loggingIn } = state.authentication;
	const { alert } = state;

	return { loggingIn, alert };
}

const actionCreators = {
	login: userActions.login,
	logout: userActions.logout,
	clearAlerts: alertActions.clear

};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as Login };
