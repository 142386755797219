function downloadBolb(blob, excelName) {
    if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, excelName);
    } else {
        let link = document.createElement('a');
        let evt = document.createEvent('HTMLEvents');
        evt.initEvent('click', false, false);
        link.href = URL.createObjectURL(blob);
        link.download = excelName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
    }
}
const format = (time) => {
    let ymd = '';
    let mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1));
    // let day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate())
    ymd += time.getFullYear() + '-'; // 获取年份。
    ymd += mouth; // 获取月份。
    // ymd += day // 获取日。
    return ymd; // 返回日期。
};

const getAllDate = (start, end) => {
    let dateArr = [];
    let startArr = start.split('-');
    let endArr = end.split('-');
    let db = new Date();
    db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
    let de = new Date();
    de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
    let unixDb = db.getTime();
    let unixDe = de.getTime();
    let stamp;
    const oneDay = 24 * 60 * 60 * 1000;

    for (stamp = unixDb; stamp <= unixDe;) {
        dateArr.push(format(new Date(parseInt(stamp, 10))));
        stamp = stamp + oneDay;
    }
    return dateArr;
};
const getAllMonth = (start, end) => {
    let dateArr = [];
    let startArr = start.split('-');
    let endArr = end.split('-');
    let db = new Date();
    db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
    let de = new Date();
    de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
    let unixDb = db.getTime();
    let unixDe = de.getTime();
    let stamp;
    const oneMonth = 24 * 60 * 60 * 1000 * 30;
    for (stamp = unixDb; stamp <= unixDe;) {
        dateArr.push(format(new Date(parseInt(stamp, 10))));
        stamp = stamp + oneMonth;
    }
    return dateArr;
};
const getQueryString = (name) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = location.hash.split('?')[1].match(reg);
    if (r != null) {
        return (r[2]);
    } else {
        return null;
    }
};
export const util = {
    downloadBolb,
    getAllDate,
    getAllMonth,
    getQueryString
};