import React from 'react';
import {
    SettingOutlined
  } from '@ant-design/icons';
import { Checkbox, Popover, Tooltip } from 'antd';
const CheckboxGroup = Checkbox.Group;
import './index.less';

class ColumnSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: [],
            indeterminate: false,
            checkAll: true,
            options: []
        };
    }
    componentDidMount() {
        const { options, selectedRowKeys } = this.props;
        this.setState({
            options,
            checkedList: selectedRowKeys
        });
    }
    componentWillReceiveProps(nextProps) {
        const { options, selectedRowKeys } = nextProps;
        this.setState({
            options,
            checkedList: selectedRowKeys

        });
    }
    onChange = list => {
        const { options } = this.state;
        this.setState({
            checkedList: list,
            indeterminate: (!!list.length && list.length < options.length),
            checkAll: list.length === options.length
        }, () => {
            this.props.onChange(list);
        });
      };
    onCheckAllChange = e => {
        const { options } = this.state;
        this.setState({
            checkedList: e.target.checked ? options : [],
            indeterminate: false,
            checkAll: e.target.checked
        }, () => {
            this.props.onChange(this.state.checkedList);
        });
      };
    render() {
        const { checkedList, indeterminate, checkAll, options } = this.state;
        const className = 'pro-table-column-setting';

        return (
            <Popover
            arrowPointAtCenter
            title={
                <div className={`${className}-title`}>
                <Checkbox
                    indeterminate={indeterminate}
                    checked={checkAll}
                    onChange={this.onCheckAllChange}
                >
                    列展示
                </Checkbox>
                {/* <a
                    onClick={() => {
                    setColumnsMap(columnRef.current);
                    }}
                >
                    重置
                </a> */}
                </div>
            }
            overlayClassName={`${className}-overlay`}
            trigger="click"
            placement="bottomRight"
            content={
                <CheckboxGroup options={options} value={checkedList} className='flex-column' onChange={this.onChange} />
            }
            >
            <Tooltip title='列设置'>
                <SettingOutlined />
            </Tooltip>
            </Popover>
        );
    }

}
export default ColumnSetting;