import { request } from '../_helpers';
import { config } from '../config';

let baseURL = config.apiAuthUrl;
// 登录授权
export async function getAuth(params) {
    return request.post(`${baseURL}/oauth/token`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
    );
};
// 发送验证码
export async function getSMSCode(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/pub/sms/code`, params);
};
export async function getSMSCodeByUser(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/pub/sms/code/user`, params);
};
// 重置密码验证码校验
export async function verifySMSCode(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/pub/sms/code/verify`, params);
};
// 重置密码校验
export async function updatePassword(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/pub/password/update`, params);
};