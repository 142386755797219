import axios from 'axios';
import { config } from '../config';

import { message, notification } from 'antd';
import { history } from './history';
import qs from 'qs';


const axiosInstance = axios.create({
	withCredentials: true,
});

axiosInstance.interceptors.response.use(
	(response) => {
		const { data } = response;
		if (data.success) {
			return data.result;
		}
		if (data.status === 0) {
			return data;
		}
		if (data.code === 0) {
			return data.data;
		}
		if (data.status === 401 || data.code === 401) {
			history.push('/user/login');
		}
		if (!data.status && !data.result && !data.message) {
			return data;
		}
		if (data.success === false) {
			message.error((data.message && data.message.error) || '服务器出错啦，请稍后再试');
		} else {
			message.error(data.message || '服务器出错啦，请稍后再试');
		}
		return Promise.reject();
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			history.push('/user/login');
			return Promise.reject(error.response.data);
		}
		notification.error({
			message: '操作失败',
			description: error.toString(),
		});
		return Promise.reject(error);
	}
);


const get = (url, params, config) => {
	let user = JSON.parse(localStorage.getItem('user')) || [];
	return axiosInstance({
		method: 'get',
		url,
		params,
		headers: {
			Authorization: user.token ? 'Bearer' + ' ' + user.token : '',
		},
		...config,
	});
};
const post = (url, data, config) => {
	let user = JSON.parse(localStorage.getItem('user')) || [];
	return axiosInstance({
		method: 'post',
		url,
		data,
		headers: {
			Authorization: user.token ? 'Bearer' + ' ' + user.token : '',
		},
		...config,
	});
};
function getParams(data) {
	let params = qs.parse(data);
	return qs.stringify(params);
}
/**
 * post 请求方法  提交格式为 formdata
 * @param url
 * @param data
 * @returns {Promise}
 */
function post_formData(url, data = {}) {
	let formData = new FormData();
	for (let key in data) {
		if (key === 'firmwireUpgradeFile') {
			formData.append(key, data[key]);
		}
		if (key === 'file') {
			formData.append(key, data[key]);
		}
	}
	delete data.file;
	delete data.firmwireUpgradeFile;
	let user = JSON.parse(localStorage.getItem('user')) || [];
	let params = getParams(data);

	url = url + '?' + params;
	return axiosInstance({
		method: 'post',
		url,
		data: formData,
		headers: {
			'content-type': 'multipart/form-data',
			Authorization: user.token ? 'Bearer' + ' ' + user.token : '',
		},
		...config,
	});
}
export const request = {
	get,
	post,
	post_formData
};
