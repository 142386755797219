import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
    HomeOutlined,
    TeamOutlined,
    ClusterOutlined,
    SettingOutlined,
    ExceptionOutlined,
    AlertOutlined
} from '@ant-design/icons';
import './index.less';
const { SubMenu } = Menu;


class Siderbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 'home',
            selectedKeys: []
        };
    }
    componentDidMount() {
        this.getSelectMenu();
        window.addEventListener('hashchange', () => {
            this.getSelectMenu();
        });
    }
    componentWillReceiveProps(nextProps) {

    }
    getSelectMenu = () => {
        // 拿到当前浏览器的hash路径
        const hashKey = location.hash.split('#')[1];
        let selectedKeys = [];
        selectedKeys.push(hashKey);
        this.setState({
            selectedKeys
        });
    }
    // 递归方法遍历菜单
    recursion = (dataSource) => {
        let map = {
            'bmc_list': {
                pathname: '/console/bmc/list'
            },
            'bmc_detail': {
                pathname: '/console/bmc/detail'
            },
            'processor_list': {
                pathname: '/console/core/list'
            },
            'role_list': {
                pathname: '/console/auth/config'
            },
            'user_list': {
                pathname: '/console/user/list'

            },
            'account_management': {
                icon: <SettingOutlined />,
            },
            'data_over_view': {
                pathname: '/console/home',
                icon: <HomeOutlined />
            },
            'processor_management': {
                pathname: '/console/core/list',
                icon: <TeamOutlined />
            },
            'bmc_management': {
                icon: <ClusterOutlined />
            },
            // 'log_and_audit': {
            //     icon: <SettingOutlined />
            // },
            // 'financial_manage': {
            //     icon: <MoneyCollectOutlined />
            // },
            'log_and_audit': {
                icon: <ExceptionOutlined />,
                pathname: '/console/log/manage',
            },
            'monitor_and_warn': {
                pathname: '/console/monitor/list',
                icon: <AlertOutlined />
            }
        };
        return (
            dataSource && dataSource.map((menu, index) => {
                let icon = '';
                let pathname = '';
                if (map[menu.menuCode]) {
                    icon = map[menu.menuCode].icon;
                }
                if (map[menu.menuCode]) {
                    pathname = map[menu.menuCode].pathname;
                }
                if (menu.level === 1) {
                    if (menu.menuCode === 'data_over_view' || menu.menuCode === 'monitor_and_warn'
                        || menu.menuCode === 'processor_management' || menu.menuCode === 'log_and_audit') {
                        return (<Menu.Item key={map[menu.menuCode] && map[menu.menuCode].pathname}
                            icon={map[menu.menuCode] && map[menu.menuCode].icon}
                            className='oms-menu-item'>
                            <Link to={{
                                pathname: map[menu.menuCode] && map[menu.menuCode].pathname,
                                query: {
                                    buttonList: menu.childMenuList
                                }
                            }} key={menu.menuCode}>{menu.desc}
                            </Link>
                        </Menu.Item>);
                    } else {
                        return (
                            <SubMenu key={menu.menuCode} title={menu.desc}
                                icon={map[menu.menuCode] && map[menu.menuCode].icon}>
                                {this.recursion(menu.childMenuList)}
                            </SubMenu>
                        );
                    }

                } else if (menu.level === 2) {
                    if (menu.id === 87) {
                        return (
                            <SubMenu key={menu.menuCode} title={menu.desc}
                                icon={map[menu.menuCode] && map[menu.menuCode].icon}>
                                {this.recursion(menu.childMenuList)}
                            </SubMenu>
                        );
                    } else {
                        return (<Menu.Item key={map[menu.menuCode] && map[menu.menuCode].pathname}
                            icon={map[menu.menuCode] && map[menu.menuCode].icon}
                            className='oms-menu-item'>
                            <Link to={{
                                pathname: map[menu.menuCode] && map[menu.menuCode].pathname,
                                query: {
                                    buttonList: menu.childMenuList
                                }
                            }} key={menu.menuCode}>{menu.desc}
                            </Link>
                        </Menu.Item>);
                    }

                } else {
                    if (menu.parentId === 87) {
                        return (<Menu.Item key={map[menu.menuCode] && map[menu.menuCode].pathname}
                            icon={map[menu.menuCode] && map[menu.menuCode].icon}
                            className='oms-menu-item'>
                            <Link to={{
                                pathname: map[menu.menuCode] && map[menu.menuCode].pathname,
                                query: {
                                    buttonList: menu.childMenuList
                                }
                            }} key={menu.menuCode}>{menu.desc}
                            </Link>
                        </Menu.Item>);
                    }
                }
            })
        );
    }
    render() {
        const { selectedKeys } = this.state;
        let treeList = JSON.parse(localStorage.getItem('permitTree'));

        return (
            <Menu
                theme='dark'
                mode='inline'
                className='oms-menu'
                style={{ borderRight: 0 }}
                defaultOpenKeys={
                    [
                        'bmc_management',
                        'account_management'
                    ]
                }
                selectedKeys={selectedKeys}
            >
                {this.recursion(treeList)}
            </Menu>
        );
    }
}
export default Siderbar;
