import { request } from '../_helpers';
import { config } from '../config';

let baseURL = config.apiAuthUrl;
let apiUrl = config.apiUrl;

// 获取选项角色列表
export async function getGroupList() {
    return request.get(`${baseURL}/aca/auth/v1/group/list`, { clientId: 1 });
};
// 获取分页角色列表
export async function getPageGroupList(params) {
    params.clientId = 1;
    return request.get(`${baseURL}/aca/auth/v1/group/page/list`, params);
};
// 获取用户列表
export async function getUserList(params) {
    params.clientId = 1;
    return request.get(`${baseURL}/aca/auth/v1/user/page/list`, params);
};
// 日志列表
export async function getLogList(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/operationlog/list/page`, params);
};
// 设备客户关系绑定记录
export async function getDeviceLogList(params) {
    return request.post(`${apiUrl}/aca/manage/v1/operationlog/padbind/list/page`, params);
};
// 新增用户
export async function addUser(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/user/register`, params);
};
// 编辑用户
export async function editUser(params) {
    return request.post(`${baseURL}/aca/auth/v1/user/update`, params);
};
// 删除用户
export async function deleteUser(params) {
    return request.get(`${baseURL}/aca/auth/v1/user/delete`, params);
};
// 新增分组
export async function addGroup(params) {
    params.clientId = 1;
    return request.post(`${baseURL}/aca/auth/v1/group/add`, params);
};
// 编辑分组
export async function editGroup(params) {
    return request.post(`${baseURL}/aca/auth/v1/group/update`, params);
};
// // 删除分组
// export async function deleteGroup(params) {
//     return request.get(`${baseURL}/aca/auth/v1/user/delete`,params);
// };
// 查询角色包含项列表（不分页）
export async function getRoleList(params) {
    return request.get(`${baseURL}/aca/auth/v1/menu/adapt/list`, params);
};
// 分组包含角色改变
export async function roleBindGroup(params) {
    return request.post(`${baseURL}/aca/auth/v1/menu/bind`, params);
};
// 获取拥有权限的菜单树
export async function getPermitTree(params) {
    return request.get(`${baseURL}/aca/auth/v1/menu/permmit/tree`, params);
};
// 获取完整的菜单树
export async function getTree() {
    return request.get(`${baseURL}/aca/auth/v1/menu/tree`, { clientId: 1 });
};
// 获取不同分组拥有权限的菜单树
export async function getGroupPermitTree(params) {
    return request.get(`${baseURL}/aca/auth/v1/menu/auth/tree`, params);
};
