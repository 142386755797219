let apiUrl;
let apiAuthUrl;

if (window.location.href.indexOf('bac-bmc.baidu-int.com') > 0
    || window.location.href.indexOf('bac-bmc.now.baidu-int.com') > 0) {
    apiUrl = 'https://bac-bmc.baidu-int.com'; // 管理后台线上地址，发布需用此地址
    apiAuthUrl = 'https://bac-bmc.baidu-int.com'; // 认证中心线上地址，发布需用此地址
} else if (window.location.href.indexOf('v1/yapweb/BMC/dev/') > 0) {
    apiUrl = 'http://bac-bmc.sandbox.bac.appspace.baidu.com'; // 管理后台测试地址
    apiAuthUrl = 'http://bac-auth.sandbox.bac.appspace.baidu.com'; // 认证中心测试地址
} else if (window.location.href.indexOf('hk03.cloudphone.ogcloud.com') > 0) {
    // 私有化部署地址
    apiUrl = 'https://hk03.cloudphone.ogcloud.com';
    apiAuthUrl = 'https://hk03.cloudphone.ogcloud.com';
} else {
    apiUrl = 'http://bac-bmc.sandbox.bac.appspace.baidu.com'; // 管理后台测试地址
    apiAuthUrl = 'http://bac-auth.sandbox.bac.appspace.baidu.com'; // 认证中心测试地址
}

export const config = {
    apiUrl,
    apiAuthUrl
};
