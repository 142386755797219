import React from 'react';
import {
    Button, Row, Col, Breadcrumb, Input, Select, Table, DatePicker, Tag, Steps,
    Tooltip, message, Modal, Dropdown, Menu, Radio, Spin, InputNumber
} from 'antd';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined, CheckCircleTwoTone, SearchOutlined, DownOutlined } from '@ant-design/icons';
import {
    getDeviceList, addBMC, BMCArmReset, editBMC, deleteBMC, idcCodeList, BMCPower, BMCReset, exportTemplate,
    importTemplate, firmwireUpload, firmwireUpgrade, firmwireStatus, firewallList
} from '../../api';
import ColumnSetting from '../../components/ColumnSetting';
import moment from 'moment';
import { util } from '../../_helpers/util';
import './index.less';

// const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Step } = Steps;
let columns = [];
let buttonArr = [];
class DevicePage extends React.Component {
    state = {
        machine: '',
        loading: false,
        selectedRowKeys: [], // 选择的列表项
        selectedRows: [], // 选择项数据数组
        BMCList: [], // BMC列表
        bmcSerialNumber: null, // 实例编号
        padroomList: [], // 机房
        socList: [],
        modelList: [],
        soc: null,
        sn: null,
        dates: [],
        resetFlag: 0,
        status: [],
        bmcLoading: false,
        statusLoading: false,
        okText: '确定',
        currentRecord: {},
        hackValue: '',
        value: '',
        online: null,
        motorRoom: null,
        firewallList: [], // 防火墙
        current: 0,
        beginTime: null, // 起始时间
        endTime: null, // 结束时间
        pageSize: 10,
        pageNo: 1,
        total: 0,
        column: [],
        firmwireUpgradeFile: null,
        run: null,
        confirmLoading: false,
        showTableLoading: false,
        showSearchLoading: false,
        modalTitle: '',
        modalContent: '',
        modalContent1: '',
        otherContent: '',
        showModal: false,
        showModalUpdate: false,
        handleOk: () => {
        },
        handleCancel: () => {
        },
        handleOk1: () => {
        },
        handleCancel1: () => {
        }
    };
    interval = null;

    componentDidMount() {
        this.fetchPadroomList();
        this.fetchDeviceList();
        this.init();
    }

    componentWillReceiveProps() {
        this.setState({
            soc: '',
            bmcSerialNumber: '',
            sn: '',
            run: '',
            online: '',
            pageSize: 10,
            pageNo: 1
        }, () => {
            this.fetchDeviceList();
        });
    }

    init = () => {
        let treeList = JSON.parse(localStorage.getItem('permitTree'));
        let buttonList = [];

        treeList && treeList.map((v, i) => {
            v.childMenuList && v.childMenuList.map((m, j) => {
                if (m.menuCode === 'bmc_list') {
                    buttonList = m.childMenuList;
                }
            });
        });
        buttonArr = buttonList && buttonList.map(v => {
            return v.menuCode;
        });
        const ForMatStatus = (props) => {
            const { status } = props;
            let maps = {
                '支持': 'blue',
                '不支持': 'volcano',
                '启用': 'blue',
                '人工禁用': 'volcano',
                '解绑禁用': 'volcano',
                '更换禁用': 'volcano',
                '体验解绑禁用': 'volcano',
                '绑定': 'blue',
                '未绑定': 'volcano',
                '已分配': 'blue',
                '未分配': 'volcano',
                '授权': 'blue',
                '未授权': 'volcano',
                '正常': 'blue',
                '运营': 'volcano',
                '维护': 'volcano',
                '受控中': '#999',
                '在线': 'green',
                '故障': 'red',
                '离线': '#cecfd1'
            };
            return <Tag color={maps[status]}>
                {status}</Tag>;
        };
        columns = [{
            title: 'BMC编号',
            dataIndex: 'bmcSerialNumber',
            align: 'center',
            fixed: 'left',
            width: 150
        }, {
            title: 'SN',
            dataIndex: 'sn',
            align: 'center'
        }, {
            title: '板卡型号',
            dataIndex: 'soc',
            align: 'center'
        }, {
            title: 'MAC地址',
            dataIndex: 'macAddress',
            align: 'center'
        }, {
            title: '管理IP',
            dataIndex: 'manageIp',
            align: 'center'
        }, {
            title: '运行状态',
            key: 'run',
            dataIndex: 'run',
            align: 'center',
            render: function (text, record, index) {
                return <ForMatStatus status={text === 0 ? '异常' : '正常'} />;
            }
        }, {
            title: '在线状态',
            dataIndex: 'online',
            align: 'center',
            render: function (text, record, index) {
                return <ForMatStatus status={text === 0 ? '未在线' : '在线'} />;
            }
        }, {
            title: '服务器功率（W）',
            key: 'powerData',
            dataIndex: 'powerData',
            align: 'center',
            render: function (text, record, index) {
                if (text === -1) {
                    return '';
                }
                return text;
            }
        }, {
            title: '机房',
            key: 'motorRoom',
            dataIndex: 'motorRoom',
            align: 'center',
            width: 80
        },
        {
            title: '防火墙',
            key: 'firewall',
            dataIndex: 'firewall',
            align: 'center',
            width: 80
        }, {
            title: '创建时间',
            key: 'createTime',
            dataIndex: 'createTime',
            align: 'center',
            width: 180,
            render: function (text, record, index) {
                return moment(text).format('YYYY-MM-DD  HH:mm:ss');
            }
        }];
        const operList = [{
            show: buttonArr.indexOf('bmc_power') > -1,
            operate: 'BMC开机',
            func: (record) => {
                record.operate = 1;
                this.BMCPower(record);
            }
        }, {
            show: buttonArr.indexOf('bmc_power') > -1,
            operate: 'BMC关机',
            func: (record) => {
                record.operate = 0;
                this.BMCPower(record);
            }
        },
        {
            operate: 'BMC重启',
            show: buttonArr.indexOf('bmc_restart') > -1,
            func: (record) => {
                this.BMCReset(record);
            }
        },
        {
            operate: '整机重启',
            show: buttonArr.indexOf('bmc_sys_restart') > -1,
            func: (record) => {
                this.BMCArmReset(record);
            }
        },
        {
            operate: '固件更新',
            show: buttonArr.indexOf('bmc_firmwire_updgrade') > -1,
            func: (record) => {
                this.updateBMC(record);
            }
        },
        {
            operate: '删除',
            show: buttonArr.indexOf('bmc_delete') > -1,
            func: (record) => {
                this.deleteBMC(record);
            }
        }]
        const onClickOper = (record, { key }) => {
            console.log(operList[key].func);
            operList[key].func(record);
        };
        // if (buttonArr.indexOf('reboot') > -1 ||
        //     buttonArr.indexOf('reset') > -1 ||
        //     buttonArr.indexOf('bind') > -1 ||
        //     buttonArr.indexOf('unbind') > -1) {
        columns.push({
            title: '操作',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (text, record) => (
                <div className='operation'>
                    <Link to={
                        {
                            pathname: '/console/bmc/detail',
                            search: `?bmcId=${record.bmcId}&type=0`,
                        }} style={{ width: 40, fontSize: 12 }}>详情页</Link>
                    <Link to={
                        {
                            pathname: '/console/bmc/detail',
                            search: `?bmcId=${record.bmcId}&type=1`,
                        }} style={{ width: 60, fontSize: 12 }}>核心板列表</Link>
                    <br />
                    <a style={{ fontSize: 12 }} onClick={this.modifyBMC.bind(this, record, 'edit')}>编辑</a>

                    <Dropdown style={{ fontSize: 12 }} overlay={() =>
                        <Menu onClick={onClickOper.bind(this, record)}>
                            {operList.map((item, i) => {
                                return item.show ? <Menu.Item key={i}>{item.operate}</Menu.Item> : null;
                            })
                            }
                        </Menu>


                    }>
                        <a style={{ fontSize: 12 }} className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                            操作<DownOutlined />
                        </a>
                    </Dropdown>
                </div >
            )
        });
        // }
        this.setState({
            column: columns
        });
    };
    fetchPadroomList = async () => {
        const res = await idcCodeList({ emType: 'machine_room' });
        const res1 = await idcCodeList({ emType: 'model' });
        const res2 = await idcCodeList({ emType: 'soc' });
        this.setState({
            padroomList: res,
            modelList: res1,
            socList: res2
        });
    };
    fetchDeviceList = async () => {
        const { bmcSerialNumber, pageSize, pageNo, soc, motorRoom, sn, run, beginTime, endTime, online } = this.state;
        let params = {
            bmcSerialNumber,
            soc,
            motorRoom,
            sn,
            online,
            run,
            beginTime: beginTime ? beginTime.format('YYYY-MM-DD  HH:mm:ss') : beginTime,
            endTime: endTime ? endTime.format('YYYY-MM-DD  HH:mm:ss') : endTime,
            pageSize,
            pageNo
        };
        this.setState({
            showTableLoading: true
        });
        try {
            const res = await getDeviceList(params);
            this.setState({
                BMCList: res.data,
                total: res.total
            });
        } catch (e) {
        }
        this.setState({
            showSearchLoading: false,
            showTableLoading: false
        });

    };

    // exportDevice = async () => {
    //     const {
    //         bmcSerialNumber, soc, motorRoom, sn, run
    //     } = this.state;
    //     let params = {
    //         bmcSerialNumber,
    //         soc,
    //         motorRoom,
    //         sn,
    //         run
    //     };
    //     try {
    //         let data = await exportDeviceList(params);
    //         let blob = new Blob([data]);
    //         const excelName = '设备列表.xlsx';
    //         util.downloadBolb(blob, excelName);
    //     } catch (e) {

    //     }
    // };


    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
        this.setState({ selectedRowKeys, selectedRows });
    };

    onSearch = async (key) => {

    };
    onPageChange = (page, pageSize) => {
        this.setState({
            pageNo: page,
            pageSize
        }, () => {
            this.fetchDeviceList();
        });
    };

    // 开断电
    BMCPower = (record) => {
        let bmcIds = '';
        this.setState({
            showModal: true,
            modalTitle: record.operate ? 'BMC开机' : 'BMC关机',
            modalContent: record.operate ? <p>BMC开机后所有核心板进入工作状态，是否对BMC开机?</p> : <p>BMC断电后可能导致设备无法正常使用，是否继续对BMC断电？</p>,
            handleOk: async () => {
                if (record.type && record.type === 'all') {
                    const { selectedRows } = this.state;
                    let array = [];
                    selectedRows.map((item, i) => {
                        array.push(item.bmcId);
                    });
                    bmcIds = array.join(',');

                } else {
                    const { bmcId } = record;
                    bmcIds = bmcId;
                }

                let params = {
                    bmcIds,
                    operate: record.operate
                };

                try {
                    await BMCPower(params);
                    message.success(record.operate ? 'BMC已开机' : 'BMC已关闭');
                } catch (error) {
                    console.log(error);
                }
                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };



    // 重启BMC
    BMCReset = (record) => {
        let bmcIds = '';
        this.setState({
            showModal: true,
            modalTitle: 'BMC重启',
            modalContent: <p>BMC重启期间可能导致设备无法正常使用，是否继续对BMC重启？</p>,
            handleOk: async () => {
                if (record.type && record.type === 'all') {
                    const { selectedRows } = this.state;
                    let array = [];
                    selectedRows.map((item, i) => {
                        array.push(item.bmcId);
                    });
                    bmcIds = array.join(',');

                } else {
                    const { bmcId } = record;
                    bmcIds = bmcId;
                }

                let params = {
                    bmcIds
                };
                this.setState({
                    showModal: false,
                    bmcLoading: true
                });
                try {
                    await BMCReset(params);
                    message.success('BMC已重启');
                    this.setState({
                        bmcLoading: false
                    });
                } catch (error) {
                    this.setState({
                        bmcLoading: false
                    });
                }
                this.setState({
                    showModal: false,
                    bmcLoading: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };

    // 整机重启BMC
    BMCArmReset = (record) => {
        let bmcIds = '';
        this.setState({
            showModal: true,
            modalTitle: 'BMC整机重启',
            modalContent: <p>所有物理设备即将重启，是否继续对整机重启？</p>,
            handleOk: async () => {
                if (record.type && record.type === 'all') {
                    const { selectedRows } = this.state;
                    let array = [];
                    selectedRows.map((item, i) => {
                        array.push(item.bmcId);
                    });
                    bmcIds = array.join(',');

                } else {
                    const { bmcId } = record;
                    bmcIds = bmcId;
                }

                let params = {
                    bmcIds
                };
                this.setState({
                    bmcLoading: true,
                    showModal: false
                });
                try {
                    await BMCArmReset(params);
                    message.success('BMC已整机重启');
                } catch (error) {
                    this.setState({
                        showModal: false
                    });
                }
                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };
    // 删除BMC
    deleteBMC = (record) => {
        let bmcIds = '';
        this.setState({
            showModal: true,
            modalTitle: '删除BMC',
            modalContent: <p>删除BMC后，所有关联的核心板也会删除，是否继续？</p>,
            handleOk: async () => {
                if (record.type && record.type === 'all') {
                    const { selectedRows } = this.state;
                    let array = [];
                    selectedRows.map((item, i) => {
                        array.push(item.bmcId);
                    });
                    bmcIds = array.join(',');

                } else {
                    const { bmcId } = record;
                    bmcIds = bmcId;
                }

                let params = {
                    bmcIds
                };

                try {
                    await deleteBMC(params);
                    message.success('BMC已删除');
                    this.fetchDeviceList();
                } catch (error) {
                }
                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    };
    getFirewallList = async (motorRoom, callback) => {
        const res = await firewallList({ emType: 'firewall', emValue: motorRoom });
        this.setState({
            firewallList: res
        }, () => {
            callback();
        });
    }

    // BMC新增编辑
    modifyBMC = (bmc, type) => {
        const { padroomList, firewallList, modelList } = this.state;
        let motorRoom;
        let firewall;
        let bmcModel;
        let getcontent = '';
        let refresh = '';
        let sn = bmc.sn || '';
        let manageIp = bmc.manageIp || '';
        let changed = false;
        let changedfirewall = false;
        let bmcCoreCount = bmc.bmcCoreCount;
        padroomList.map((v, i) => {
            if (v.emKey === bmc.motorRoom) {
                motorRoom = v.emValue;
            }
        });
        modelList.map((v, i) => {
            if (v.emKey === bmc.bmcModel) {
                bmcModel = v.emKey;
            }
        });
        firewallList.map((v, i) => {
            if (v.emKey === bmc.firewall) {
                firewall = v.emKey;
            }
        });
        motorRoom = motorRoom || padroomList[0]?.emValue;
        bmcModel = bmcModel || modelList[0]?.emKey;

        let searchInfoChange = (type, e) => {
            let value;
            if (e) {
                value = e.target ? (e.target.value).trim() : e;
            } else {
                value = e;
            }
            ;
            switch (type) {
                case 'motorRoom': {
                    motorRoom = value;
                    firewall = null;
                    this.getFirewallList(motorRoom, refresh);
                    break;
                }
                case 'firewall': {
                    firewall = value;
                    changedfirewall = true;
                    break;
                }
                case 'bmcModel': {
                    bmcModel = value;
                    break;
                }
                case 'sn': {
                    sn = value;
                    break;
                }
                case 'manageIp': {
                    manageIp = value;
                    break;
                }
                case 'bmcCoreCount': {
                    bmcCoreCount = value;
                    break;
                }
            }
            refresh();
        };
        refresh = () => {
            const { padroomList, firewallList, modelList } = this.state;
            firewall = (!changed || changedfirewall) ? firewall : firewallList[0]?.emKey;
            if (!changed && type === 'edit') {
                firewall = bmc.firewall;
            }
            changed = true;
            changedfirewall = false;

            getcontent = () => {

                this.setState({
                    showModal: true,
                    modalTitle: type === 'add' ? '新增BMC' : '编辑BMC',
                    modalContent: (
                        <>


                            <Row style={{ marginBottom: 10 }}>
                                <Col span={4} className='label'>机房:</Col>
                                <Col span={16} className='value'>
                                    <Select
                                        onChange={searchInfoChange.bind(this, 'motorRoom')}
                                        style={{ width: 200 }}
                                        defaultValue={motorRoom}
                                        placeholder='请选择机房'>
                                        {
                                            padroomList.map((item, index) => {
                                                return <Option value={item.emValue}>{item.emKey}</Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col span={4} className='label'>防火墙:</Col>
                                <Col span={16} className='value'>
                                    <Select
                                        onChange={searchInfoChange.bind(this, 'firewall')}
                                        style={{ width: 200 }}
                                        value={firewall}
                                        placeholder='请选择防火墙'>
                                        {
                                            firewallList.map((item, index) => {
                                                return <Option value={item.emKey}>{item.emKey}</Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col span={4} className='label'>BMC型号:</Col>
                                <Col span={16} className='value'>
                                    <Select
                                        onChange={searchInfoChange.bind(this, 'bmcModel')}
                                        style={{ width: 200 }}
                                        defaultValue={bmcModel}
                                        placeholder='请选择BMC型号'>
                                        {
                                            modelList.map((item, index) => {
                                                return <Option value={item.emKey}>{item.emKey}</Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col span={4} className='label'>SN:</Col>
                                <Col span={16} className='value'>
                                    <Input placeholder='输入SN' allowClear
                                        className='search-device-name'
                                        defaultValue={sn}
                                        style={{ width: 200 }}
                                        onChange={searchInfoChange.bind(this, 'sn')} />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col span={4} className='label'>管理IP:</Col>
                                <Col span={16} className='value'>
                                    <Input placeholder='输入管理IP' allowClear
                                        className='search-device-name'
                                        defaultValue={manageIp}
                                        style={{ width: 200 }}
                                        onChange={searchInfoChange.bind(this, 'manageIp')} />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col span={4} className='label'>核心板数量:</Col>
                                <Col span={16} className='value'>
                                    <InputNumber min={0} placeholder='输入核心板数量' allowClear
                                        className='search-device-name'
                                        defaultValue={bmcCoreCount}
                                        style={{ width: 200 }}
                                        onChange={searchInfoChange.bind(this, 'bmcCoreCount')} />
                                </Col>
                            </Row>
                        </>),
                    handleOk: async () => {
                        let params = {
                            bmcModel,
                            sn,
                            manageIp,
                            motorRoom,
                            firewall,
                            bmcCoreCount
                        };
                        try {
                            if (type === 'add') {
                                if (!bmcCoreCount) {
                                    message.error('核心板数量不能为空');
                                    return;
                                }
                                await addBMC(params);
                            } else if (type === 'edit') {
                                params.bmcId = bmc.bmcId;
                                await editBMC(params);
                            }
                            message.success('操作成功');
                            this.setState({
                                showModal: false,
                            });
                            this.fetchDeviceList();
                        } catch (error) {
                        }

                    },
                    handleCancel: () => {
                        this.setState({
                            showModal: false
                        });
                    }
                });
            }
            getcontent();
        };
        this.getFirewallList(motorRoom, refresh);



    };
    //  导入BMC
    importBMC = () => {
        this.setState({
            showModal: true,
            modalTitle: '导入BMC',
            modalContent: (
                <>
                    <Row style={{ marginBottom: 20 }}>
                        <Col className='label'>注:先点击“下载模板”，在下载的模板中将BMC信息填写完整后，再“上传文件”导入BMC</Col>
                    </Row>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={12} className='label' push={2}> <Button type='primary' onClick={this.importTem}
                        >
                            上传文件</Button>
                            <input type='file' className='file' accept='.xlsx' onChange={this.importTem} multiple />
                        </Col>
                        <Col span={12} className='label' push={2}> <Button type='primary' onClick={this.exportTem}
                        >
                            下载模板</Button></Col>
                    </Row>
                </>),
            handleOk: () => {
                this.setState({
                    showModal: false
                });
            },
            handleCancel: () => {
                this.setState({
                    showModal: false
                });
            }
        });
    }
    // 导出
    exportTem = async () => {
        try {
            const res = await exportTemplate();
            let blob = new Blob([res]);
            const excelName = 'BMC导入模板.xlsx';
            util.downloadBolb(blob, excelName);
        } catch (e) {
        }
    }

    // 导入
    importTem = async ({ target }) => {
        try {
            let file = target.files[0];
            let params = {
                file
            };
            await importTemplate(params);
            message.success('导入成功');
            this.fetchOperatorsList();
            this.setState({
                showIpModal: false
            });
        } catch (e) {
        }
    }
    // getUpdateStep1Tmp = (record) => {
    //     let resetFlag = '';
    //     let appInfoChange = (type, e) => {
    //         const value = e.target ? (e.target.value).trim() : e;
    //         switch (type) {
    //             case 'resetFlag': {
    //                 resetFlag = value;
    //                 break;
    //             }
    //         };
    //     };
    // }
    appInfoChange = (type, e) => {
        let { resetFlag } = this.state;
        const value = e.target.value;
        switch (type) {
            case 'resetFlag': {
                resetFlag = value;
                break;
            }
        };
        this.setState({
            resetFlag
        });
    };
    uploadFile = async (record) => {
        let bmcIps = '';
        if (record.type && record.type === 'all') {
            const { selectedRows } = this.state;
            let array = [];
            selectedRows.map((item, i) => {
                array.push(item.manageIp);
            });
            bmcIps = array.join(',');

        } else {
            const { manageIp } = record;
            bmcIps = manageIp;
        }
        const { firmwireUpgradeFile } = this.state;

        let params = {
            bmcIps,
            firmwireUpgradeFile
        };
        this.setState({
            confirmLoading: true,
            okText: '上传中'
        });
        try {
            await firmwireUpload(params);
        } catch (error) {
            this.setState({
                showModal: false
            });
        }

        this.setState({
            confirmLoading: false,
            okText: '确定'
        });
    }

    getUpdateStep2Tmp = async (record) => {
        let { resetFlag } = this.state;
        let bmcIps = '';
        if (record.type && record.type === 'all') {
            const { selectedRows } = this.state;
            let array = [];
            selectedRows.map((item, i) => {
                array.push(item.manageIp);
            });
            bmcIps = array.join(',');

        } else {
            const { manageIp } = record;
            bmcIps = manageIp;
        }

        let params = {
            bmcIps,
            resetFlag
        };

        this.setState({
            statusLoading: true
        });
        try {
            await firmwireUpgrade(params);
            message.success('升级请求已发送');
            this.getFirmwireStatus(params.bmcIps);

        } catch (error) {
            this.setState({
                statusLoading: false,
                showModal: false
            });
        }
    }
    getFirmwireStatus = async (ips) => {
        let { status } = this.state;
        let ipArray = ips.split(',') || [];
        ipArray.map((v, i) => {
            this.interval = setInterval(async () => {
                try {
                    let res = await firmwireStatus({ bmcIp: v });
                    if (res.status === 2) {
                        clearInterval(this.interval);
                        this.setState({
                            current: 2,
                            statusLoading: false
                        });
                    }
                    status.push(res);
                    this.setState({
                        status
                    });
                } catch (error) {
                }
            }, 10000);

        });

    }
    // BMC固件升级
    updateBMC = (record) => {
        // this.getUpdateStep1Tmp(record)
        this.setState({
            showModalUpdate: true,
            modalTitle: '更新固件',
            currentRecord: record,
            handleOk1: async () => {
            },
            handleCancel1: () => {
                this.setState({
                    showModalUpdate: false
                });
            }
        });

    };


    // 搜索项变化触发
    searchInfoChange = (type, e) => {
        let { bmcSerialNumber, soc, motorRoom, beginTime, endTime, sn, run, online } = this.state;
        let value;
        if (e) {
            value = e.target ? (e.target.value).trim() : e;
        } else {
            value = e;
        }
        ;
        switch (type) {
            case 'bmcSerialNumber': {
                bmcSerialNumber = value;
                break;

            }
            case 'soc': {
                soc = value;
                break;
            }
            case 'sn': {
                sn = value;
                break;
            }
            case 'online': {
                online = value;
                break;
            }
            case 'run': {
                run = value;
                break;
            }
            case 'motorRoom': {
                motorRoom = value;
                break;

            }
            case 'time': {
                if (!e) {
                    beginTime = value;
                    endTime = value;
                } else {
                    beginTime = value[0];
                    endTime = value[1];
                }
            }
        }

        this.setState({
            pageNo: 1,
            bmcSerialNumber,
            soc,
            motorRoom,
            sn,
            run,
            online,
            beginTime,
            endTime
        }, () => {
            if (type === 'soc' || type === 'run' || type === 'motorRoom' || type === 'online') {
                this.search();
            }
        });
    };

    search = () => {
        this.setState({
            pageNo: 1,
            selectedRowKeys: [],
            selectedRows: []
        }, () => {
            this.fetchDeviceList();
        });

    };

    render() {
        const cssPre = 'device';
        const {
            loading, otherContent, sn, selectedRowKeys, confirmLoading, BMCList, modalTitle, socList,
            showTableLoading, showSearchLoading, pageNo, soc, online, column, padroomList, showModalUpdate,
            total, showModal, handleCancel, handleOk, handleOk1, handleCancel1, modalContent, firmwireUpgradeFile,
            bmcSerialNumber, motorRoom, run, pageSize, currentRecord, okText, current, status } = this.state;
        // const disabledDate = current => {
        //     if (!dates || dates.length === 0) {
        //         return false;
        //     }
        //     const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
        //     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
        //     return tooEarly || tooLate;
        // };
        // const onOpenChange = open => {
        //     if (open) {
        //         this.setState({
        //             hackValue: [],
        //             dates: []
        //         })
        //     } else {
        //         this.setState({
        //             hackValue: undefined
        //         })
        //     }
        // };
        BMCList && BMCList.map((v, i) => {
            v.key = v.bmcId;
        });
        const next = () => {
            if (firmwireUpgradeFile === null) {
                message.info('选择固件镜像');
                return;
            }
            this.uploadFile(currentRecord);
            this.setState({
                showModal: true,
                modalTitle: '',
                modalContent: <p>即将写入新的固件镜像，开始后操作不能中断，点击确定继续？</p>,
                handleOk: async () => {
                    this.setState({
                        showModal: false
                    });
                    this.setState({
                        current: current + 1
                    }, () => {
                        this.getUpdateStep2Tmp(currentRecord);
                    });
                },
                handleCancel: () => {
                    this.setState({
                        showModal: false
                    });
                }
            });
        }

        // const prev = () => {
        //     this.setState({
        //         current: current - 1
        //     });
        // };

        let fileChange = ({ target }) => {
            this.setState({
                firmwireUpgradeFile: target.files[0]
            }, () => {
                this.updateBMC(currentRecord);
            });
        };
        const culumnsData = columns.map((item) => {
            return item.title;
        });
        const culumnsSelectData = column.map((item) => {
            return item.title;
        });
        const columnsChange = (list) => {
            let temp = [];
            temp = columns.filter((item) => {
                return list.indexOf(item.title) !== -1;
            });
            this.setState({
                column: temp
            });
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-header`}>
                    <h4 className='title'>BMC列表</h4>
                    <Breadcrumb>
                        <Breadcrumb.Item>BMC管理</Breadcrumb.Item>
                        <Breadcrumb.Item>BMC列表</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Spin spinning={this.state.bmcLoading} tip='正在重启，请稍候再试' delay={500}>
                    <div className={`${cssPre}-content`}>
                        <div className='block'>
                            {/* {buttonArr.indexOf('device_list_search') > -1 && */}
                            <div className='search'>
                                <Input.Group compact className='search-wrapper'>
                                    <div className='left'>
                                        <div className='search-ida'>
                                            <span className='title'>BMC名称：</span>
                                            <Input placeholder='输入BMC名称' allowClear
                                                className='search-device-name'
                                                value={bmcSerialNumber}
                                                style={{ width: 150, marginBottom: 20 }}
                                                onChange={this.searchInfoChange.bind(this, 'bmcSerialNumber')} />
                                        </div>
                                        <div className='search-ida'>
                                            <span className='title'>SN：&nbsp;&nbsp;</span>
                                            <Input placeholder='输入SN' allowClear
                                                className='search-device-name'
                                                value={sn}
                                                style={{ width: 150, marginBottom: 20 }}
                                                onChange={this.searchInfoChange.bind(this, 'sn')} />
                                        </div>

                                        <div className='search-ida'>
                                            <span className='title'>运行状态：</span>
                                            <Select onChange={
                                                this.searchInfoChange.bind(this, 'run')}
                                                showSearch
                                                style={{ width: 150, marginBottom: 20 }}
                                                value={run}
                                                placeholder='请选择运行状态'>
                                                <Option value={null}>所有</Option>
                                                <Option value={0}>异常</Option>
                                                <Option value={1}>正常</Option>
                                            </Select>
                                        </div>
                                        <div className='search-ida'>
                                            <span className='title'>在线状态：</span>
                                            <Select onChange={
                                                this.searchInfoChange.bind(this, 'online')}
                                                showSearch
                                                style={{ width: 150 }}
                                                value={online}
                                                placeholder='请选择在线状态'>
                                                <Option value={null}>所有</Option>
                                                <Option value={0}>未在线</Option>
                                                <Option value={1}>在线</Option>
                                            </Select>
                                        </div>
                                        <div className='search-ida'>
                                            <span className='title'>板卡型号：</span>
                                            <Select
                                                onChange={this.searchInfoChange.bind(this, 'soc')}
                                                style={{ width: 150 }}
                                                value={soc}
                                                placeholder='请选择板卡型号'>
                                                <Option value={null}>所有</Option>
                                                {
                                                    socList && socList.map((item, index) => {
                                                        return <Option value={item.emValue}>{item.emKey}</Option>;
                                                    })
                                                }
                                            </Select>
                                        </div>

                                        <div className='search-ida'>
                                            <span className='title'>机房：</span>
                                            <Select
                                                onChange={this.searchInfoChange.bind(this, 'motorRoom')}
                                                style={{ width: 150 }}
                                                value={motorRoom}
                                                placeholder='请选择机房'>
                                                <Option value={null}>所有</Option>
                                                {
                                                    padroomList && padroomList.map((item, index) => {
                                                        return <Option value={item.emValue}>{item.emKey}</Option>;
                                                    })
                                                }
                                            </Select>
                                        </div>

                                        <div className='search-ida'>
                                            <span className='title'>创建时间：</span>
                                            <RangePicker
                                                showTime
                                                // picker='month'
                                                onCalendarChange={val => { this.setState({ dates: val }); }}
                                                // disabledDate={disabledDate}
                                                style={{ width: 385 }}
                                                // value={hackValue || value}
                                                // onOpenChange={onOpenChange}
                                                onChange={this.searchInfoChange.bind(this, 'time')}
                                            />
                                        </div>
                                        <Button type='primary' icon={<SearchOutlined />} loading={showSearchLoading}
                                            onClick={this.search}>查询</Button>
                                        {/* <Button type='primary' icon={expansionType === 'close' ?
                                        <DownOutlined/> : <UpOutlined/>} loading={showSearchLoading}
                                            onClick={this.expansion} style={{marginLeft: 10}}>
                                        {expansionType === 'open' ? '收起' : '展开'}
                                    </Button> */}
                                    </div>
                                    <div className='right'></div>

                                </Input.Group>
                            </div>
                            {/* } */}
                            <div className='operation'>
                                <div className='left'>
                                    {buttonArr.indexOf('bmc_add') > -1 &&
                                        <Button type='primary' onClick={this.modifyBMC.bind(this, {}, 'add')}>
                                            新增BMC
                                        </Button>
                                    }

                                    {buttonArr.indexOf('bmc_import') > -1 &&
                                        <Button type='primary' onClick={this.importBMC}>
                                            批量导入BMC
                                        </Button>
                                    }

                                    {buttonArr.indexOf('bmc_power') > -1 &&
                                        <Button
                                            type='primary'
                                            onClick={this.BMCPower.bind(this, { type: 'all', operate: 1 })}
                                            disabled={!hasSelected}
                                        >
                                            BMC开机
                                        </Button>
                                    }
                                    {buttonArr.indexOf('bmc_power') > -1 &&
                                        <Button
                                            type='primary'
                                            onClick={this.BMCPower.bind(this,
                                                { type: 'all', operate: 0 })}
                                            disabled={!hasSelected}
                                        >
                                            BMC关机
                                        </Button>
                                    }
                                    {buttonArr.indexOf('bmc_restart') > -1 &&
                                        <Button
                                            type='primary'
                                            onClick={this.BMCReset.bind(this, { type: 'all' })}
                                            disabled={!hasSelected}
                                        >
                                            BMC重启
                                        </Button>
                                    }
                                    {buttonArr.indexOf('bmc_sys_restart') > -1 &&

                                        <Button type='primary' onClick={this.BMCArmReset.bind(this,
                                            { type: 'all' })}
                                            disabled={!hasSelected} loading={loading}>
                                            整机重启
                                        </Button>
                                    }
                                    {buttonArr.indexOf('bmc_firmwire_updgrade') > -1 &&

                                        <Button type='primary' onClick={this.updateBMC.bind(this,
                                            { type: 'all' })}
                                            disabled={!hasSelected}
                                            loading={loading}>
                                            更新BMC固件
                                        </Button>
                                    }
                                    {buttonArr.indexOf('bmc_delete') > -1 &&
                                        <Button type='primary' danger onClick={this.deleteBMC.bind(this,
                                            { type: 'all' })}
                                            disabled={!hasSelected}>
                                            删除BMC
                                        </Button>
                                    }
                                    <Tooltip title={() => {
                                        return <>
                                            <p>BMC开机：对选中BMC关联所有核心板开机</p>
                                            <p>BMC关机：对选中BMC关联所有核心板关机</p>
                                            <p>BMC重启：对选中BMC本身重启，不包含核心板</p>
                                            <p>整机重启：对选中BMC关联的所有核心板重启</p>
                                            <p>固件更新：对选中BMC本身固件更新，更新后BMC详情中“固件版本号”随之变化。</p>
                                            <p>删除BMC：对选中BMC及关联的核心板删除</p>
                                        </>;
                                    }}>

                                        <QuestionCircleOutlined style={{ color: 'rgb(2, 167, 240)', marginLeft: 10 }} />
                                    </Tooltip>
                                    {/* {buttonArr.indexOf('device_list_export') > -1 &&
                                    <Button type='primary' style={{ marginLeft: 10 }} onClick={this.exportDevice}>
                                        导出
                                    </Button>
                                } */}
                                    <span style={{ marginLeft: 8, width: '82px' }}>
                                        {hasSelected ? `已选择${selectedRowKeys.length}项` : ''}
                                    </span>
                                </div>
                                <div className='right'>
                                    <ColumnSetting options={culumnsData} selectedRowKeys={culumnsSelectData}
                                        onChange={columnsChange}></ColumnSetting>
                                </div>
                            </div>
                        </div>

                        <div className='result'>
                            <Table
                                rowSelection={rowSelection}
                                columns={column}
                                dataSource={BMCList}
                                scroll={{ x: 1300 }} sticky
                                loading={showTableLoading}
                                showSorterTooltip={false}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    current: pageNo,
                                    pageSize,
                                    showTotal: total => {
                                        return `总计${total}项`;
                                    },
                                    onChange: this.onPageChange,
                                    total: total
                                }}
                            />
                        </div>
                    </div>
                </Spin>
                <Modal title={modalTitle} visible={showModal} onOk={handleOk} onCancel={handleCancel} okText={okText}
                    cancelText='取消'
                    destroyOnClose={true}
                    maskClosable={false}
                    confirmLoading={confirmLoading}>
                    {modalContent}
                    {otherContent}
                </Modal>
                <Modal title={modalTitle} visible={showModalUpdate} onOk={handleOk1}
                    onCancel={handleCancel1} footer={null}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    confirmLoading={confirmLoading}>
                    <div className='update' style={{ marginTop: 20 }}>
                        <Steps size='small' current={current} className='steps'>
                            <Step title='固件上传' />
                            <Step title='固件更新' />
                            <Step title='完成' />
                        </Steps>
                        <div className={['steps-content', current === 1 ? 'steps-content-status' : '']}>
                            {
                                current === 0 &&
                                <>

                                    <Row style={{ marginTop: 20 }} align='middle'>
                                        <Col span={6} className='label'><span style={{ color: 'red' }}>*</span>
                                            选择固件镜像：</Col>
                                        <Col span={16} className='value'>
                                            {/* <Button type='primary' size='small'
                    // onClick={this.importTem}
                    >
                        浏览</Button> */}
                                            <input type='file' accept='.bin' onChange={fileChange} multiple />
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: 20 }} align='middle'>
                                        <Col span={6} className='label'><span style={{ color: 'red' }}>*</span>
                                            恢复出厂配置：</Col>
                                        <Col span={16} className='value'>
                                            <Radio.Group onChange={this.appInfoChange.bind(this, 'resetFlag')}
                                                defaultValue={0}>
                                                <Radio value={1}>启用</Radio>
                                                <Radio value={0}>禁用</Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                </>
                            }

                            {
                                current === 1 &&
                                <>
                                    <Row style={{ marginTop: 20 }} align='middle'>
                                        <Col span={24} className='label'>BMC固件更新进度：</Col>
                                    </Row>
                                    <Row style={{ marginTop: 20 }} align='middle'>
                                        <Col span={10} className='label'>服务器IP</Col>
                                        <Col span={14} className='value'>进度</Col>
                                    </Row>
                                    {
                                        status.map((v, i) => {
                                            return <Row style={{ marginTop: 10 }} align='middle' key={i}>
                                                <Col span={10} className='label'>{v.bmcIp}</Col>
                                                <Col span={14} className='value'>{v.desc}</Col>
                                            </Row>;
                                        })
                                    }
                                    <Spin size='small' spinning={this.state.statusLoading}></Spin>
                                </>
                            }
                            {
                                current === 2 &&
                                <div className='step-complete'>
                                    <CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: 30 }} />
                                    <div>固件已完成升级</div>
                                </div>
                            }
                        </div>
                        <div className='steps-action'>
                            {current < 1 && (
                                <Button type='primary' onClick={() => next()}>
                                    下一步
                                </Button>
                            )}
                            {current === 2 && (
                                <Button type='primary' onClick={() => {
                                    this.setState({
                                        showModalUpdate: false
                                    });
                                }}>
                                    完成
                                </Button>
                            )}
                            {/* {current > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    上一步
                                </Button>
                            )} */}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default DevicePage;

