import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import { Router, Route } from 'react-router-dom';
import Sidebar from './components/Menu';
import { User } from './components/User';
import { getPermitTree } from './api';

import './App.less';
import { Login } from './pages/Login';
import Routes from './routes/index';
import { history } from './_helpers';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';

const { Header, Sider, Content } = Layout;

class App extends React.Component {
	state = {
	};
	componentWillMount() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (!user || !user.token) {
			history.push('/user/login');
			return;
		}
		this.fetchPermitTree();
	}
	fetchPermitTree = async () => {
		try {
			const res = await getPermitTree({});
			localStorage.setItem('permitTree', JSON.stringify(res));
		} catch (e) {
		}
	}
	render() {
		const { tab } = this.state;
		return (
			<ConfigProvider locale={zhCN}>
				<Router history={history}>
					<Route path='/user/login' component={Login} />
					<Route path='/console'>
						<Layout
							className='oms'
							style={{ minHeight: '100vh' }}
						>
							<Layout>
								<Sider width={200}>
									<div
										style={{ cursor: 'pointer' }}
										className='oms-header-logo'
									>
										{/* <span className='ico'></span> */}
										<h5>ARM云BMC控制台</h5>
									</div>
									<Sidebar
										tab={tab}
										ref='updateList'
									/>
								</Sider>
								<Layout>
									<Header className='oms-header'>
										<User />
									</Header>
									<Content className='oms-content'>
										<Routes />
									</Content>
								</Layout>
							</Layout>
						</Layout>
					</Route>
				</Router>
			</ConfigProvider>
		);
	}
}

export default App;
