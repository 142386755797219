import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Modal, Button, Table, DatePicker } from 'antd';
import { getLogList } from '../../api';
import { SearchOutlined } from '@ant-design/icons';
import './index.less';
import moment from 'moment';

const { RangePicker } = DatePicker;

class LogManagePage extends React.Component {
    state = {
        operator: '',
        operateType: 'LOGIN',
        operateTimeFrom: null, // 账单起始时间
        operateTimeTo: null, // 账单结束时间
        logList: [], // 用户列表
        column: [],
        showSearchLoading: false,
        showTableLoading: false,
        pageSize: 10,
        pageNo: 1,
        total: 0,
        isModalVisible: false,
        requestParamContent: '',
        auth: [],
        authName: {
            'op_log_search': '用户系统日志',
            'pad_bind_log_search': '业务操作日志'
        },
        pathname: {
            'op_log_search': '/console/log/manage',
            'pad_bind_log_search': '/console/log/device'
        },
        path: 'op_log_search'
    };

    componentDidMount() {
        this.fetchLogList();
        const column = [
            {
                title: '日志ID',
                dataIndex: 'id',
                align: 'center',
            },
            {
                title: '功能模块',
                dataIndex: 'operateModule',
                align: 'center',
            },
            {
                title: '操作名称',
                dataIndex: 'operateUnitName',
                align: 'center',
            },
            {
                title: '操作时间',
                dataIndex: 'operateTime',
                align: 'center',
                render: function (text) {
                    return moment(text).format('YYYY-MM-DD HH:mm:ss');
                }
            },
            {
                title: '操作人员',
                dataIndex: 'operator',
                align: 'center',
            }
        ];
        // const auth = this.props.location.query?.auth || [];
        // if (!auth || !auth.length) {
        //     this.props.history.push('/console/log/log');
        // }
        this.setState({
            // auth,
            column
        });
    }

    onLookUpClick(val) {
        this.setState({
            isModalVisible: true,
            requestParamContent: val
        });
    }

    fetchLogList() {
        const {
            operator,
            operateType,
            pageNo,
            pageSize,
            operateTimeTo,
            operateTimeFrom
        } = this.state;

        const params = {
            operator,
            operateType,
            pageNo,
            pageSize,
            operateTimeTo: operateTimeTo && operateTimeTo.valueOf(),
            operateTimeFrom: operateTimeFrom && operateTimeFrom.valueOf()
        };
        this.setState({
            showTableLoading: true
        });
        getLogList(params).then((res) => {
            this.setState({
                logList: res.data,
                total: res.total
            });
        });
        this.setState({
            showSearchLoading: false,
            showTableLoading: false
        });

    };

    searchInfoChange = (type, e, dateString) => {
        let { operator, operateTimeTo, operateTimeFrom
        } = this.state;

        if (type === 'operator') {
            operator = e.target.value.trim();
        } else if (type === 'billTime') {
            operateTimeFrom = e && e[0];
            operateTimeTo = e && e[1];
        }

        this.setState({
            operator,
            operateTimeFrom,
            operateTimeTo
        }, () => {
            this.search();
        });
    };

    search = () => {
        this.setState({
            pageNo: 1
        }, () => {
            this.fetchLogList();
        });
    };

    onPageChange = (page, pageSize) => {
        this.setState({
            pageNo: page,
            pageSize
        }, () => {
            this.fetchLogList();
        });
    };

    handleOk() {
        console.log('handleOk');
        this.setState({
            isModalVisible: false
        });
    }

    handleCancel() {
        console.log('handleCancel');
        this.setState({
            isModalVisible: false
        });
    }

    render() {
        const cssPre = 'log';

        const {
            logList,
            showTableLoading,
            showSearchLoading,
            total,
            pageNo,
            column,
            isModalVisible,
            requestParamContent,
            authName,
            path,
            pathname
        } = this.state;

        logList.map((item, index) => {
            item.key = index;
            return item;
        });

        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-header`}>
                    <div className='wrap'>
                        <h4 className='title'>日志审计</h4>
                        <div className='auth-wrap'>
                            {/* {
                                auth.map((item, key) => ( */}
                            <Link
                                className={`item ${path === 'op_log_search' ? 'active' : ''}`}
                                key={0}
                                to={{
                                    pathname: pathname.op_log_search,
                                    // query: {auth}
                                }}
                            >
                                {authName.op_log_search}
                            </Link>
                            <Link
                                className={`item ${path === 'pad_bind_log_search' ? 'active' : ''}`}
                                key={1}
                                to={{
                                    pathname: pathname.pad_bind_log_search,
                                    // query: {auth}
                                }}
                            >
                                {authName.pad_bind_log_search}
                            </Link>
                            {/* ))
                            } */}
                        </div>
                    </div>


                </div>
                <div className={`${cssPre}-content`}>
                    <div className='block'>
                        {
                            <div className='search'>
                                <Input.Group className='search-wrapper' compact>
                                    <div className='search-ida'>
                                        <span>操作人员：</span>
                                        <Input
                                            placeholder=''
                                            allowClear
                                            className='search-device-name'
                                            onChange={this.searchInfoChange.bind(this, 'operator')}
                                        />
                                    </div>

                                    <div className='search-ida'>
                                        <span>操作时间：</span>
                                        <RangePicker
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            onChange={this.searchInfoChange.bind(this, 'billTime')}
                                        />
                                    </div>
                                    <Button
                                        type='primary'
                                        icon={<SearchOutlined />}
                                        loading={showSearchLoading}
                                        onClick={this.search}
                                    >
                                        查询
                                    </Button>
                                </Input.Group>
                            </div>
                        }
                    </div>
                    <div className='result'>
                        <Table
                            columns={column}
                            dataSource={logList}
                            loading={showTableLoading}
                            showSorterTooltip={false}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                current: pageNo,
                                showTotal: total => {
                                    return `总计${total}项`;
                                },
                                onChange: this.onPageChange,
                                total: total
                            }}
                        />
                    </div>
                </div>
                <Modal
                    title="请求参数"
                    visible={isModalVisible}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                >
                    {requestParamContent}
                </Modal>
            </div>
        );
    }
}

export default LogManagePage;

