import React from 'react';
import { Tabs, Tooltip } from 'antd';
import ErrorList from '../../components/ErrorList/';
import { QuestionCircleOutlined } from '@ant-design/icons';

// import { getUserInfo } from '../../api';
import './index.less';
const { TabPane } = Tabs;
class BMCDetailPage extends React.Component {
    state = {
        changed: '0',
        level: 2
    };

    async componentWillMount() {
        let data = this.props.location.query;
        let level = 2;
        let changed = '0';
        if (data && data.level) {
            level = data.level;
            changed = level === 2 ? '1' : '0';
        }
        this.setState({
            changed,
            level
        });
    }
    callback = (key) => {
        this.setState({
            changed: key
        });
    }
    render() {
        const cssPre = 'monitor';
        const { changed, level } = this.state;
        return (
            <div className={`${cssPre}`}>
                <div className={`${cssPre}-header`}>
                    <h4 className='title'>监控与报警</h4>
                    <Tooltip title={() => {
                        return <>
                            <p>BMC异常：警告类型包含：断电、电源异常、风扇异常、传感器异常</p>
                            <p>核心板异常：警告类型：断电、未获取到IP、未在位</p>
                            <p>紧急：BMC断电、电源异常、风扇异常</p>
                            <p>严重：核心板断电、无法获取iP、未在位</p>
                            <p>轻微：传感器异常</p>
                        </>;
                    }}>

                        <QuestionCircleOutlined style={{ color: 'rgb(2, 167, 240)', marginLeft: 10 }} />
                    </Tooltip>
                    {/* <Breadcrumb>
                        <Breadcrumb.Item>异常</Breadcrumb.Item>
                        <Breadcrumb.Item>监控与警告</Breadcrumb.Item>
                    </Breadcrumb> */}
                </div>
                <div className={`${cssPre}-content`}>
                    <Tabs activeKey={changed} onChange={this.callback} style={{ margin: 10 }}>
                        <TabPane tab='BMC异常' key='0' >
                            <ErrorList type='bmc' level={level}></ErrorList>
                        </TabPane>
                        <TabPane tab='核心板异常' key='1'>
                            <ErrorList type='core' level={level}></ErrorList>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default BMCDetailPage;

