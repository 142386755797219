import React from 'react';
import {
	Row, Col, Breadcrumb, Select, Modal, Input, Button, Table, message, Space, Tag, Tabs,
	Tree
} from 'antd';
import { EditOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import {
	getGroupList, getPageGroupList, addGroup, editGroup, roleBindGroup,
	getTree, getGroupPermitTree
} from '../../api';
import ColumnSetting from '../../components/ColumnSetting';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

import './index.less';
let buttonArr = [];
let columns = [];
class AuthConfig extends React.Component {
	state = {
		groupList: [], // 不分页选项列表
		groupPageList: [], // 分页详细分组列表
		groupId: '',
		pageSize: 10,
		total: 0,
		showSearchLoading: false,
		showTableLoading: false,
		pageNo: 1,
		confirmLoading: false,
		column: [],
		modalTitle: '',
		modalContent: '',
		treeList: [], // 完整权限树
		showModal: false,
		handleOk: () => { },
		handleCancel: () => { }
	};
	async componentDidMount() {
		this.fetchGroupList();
		this.fetchGroupPageList();
		this.fetchTreeList();
		this.init();
	}
	init = () => {
		let treeList = JSON.parse(localStorage.getItem('permitTree'));
		let buttonList = [];
		treeList && treeList.map((v, i) => {
			v.childMenuList && v.childMenuList.map((m, j) => {
				if (m.menuCode === 'role_list') {
					buttonList = m.childMenuList;
				}
			});
		});
		buttonArr = buttonList && buttonList.map(v => {
			return v.menuCode;
		});
		columns = [
			{
				title: '角色名称',
				dataIndex: 'groupName',
				align: 'center',
				render: (text, record) => (<a onClick={this.lookGroupDetail.bind(this, record)}>{text}</a>)

			},
			{
				title: '人员数量',
				dataIndex: 'groupMemberSum',
				align: 'center',
			},
			{
				title: '角色描述',
				dataIndex: 'groupDesc',
				align: 'center',
			}
		];
		if (buttonArr.indexOf('role_update') > -1 ||
			buttonArr.indexOf('role_delete') > -1 ||
			buttonArr.indexOf('role_setting') > -1) {
			columns.push(
				{
					title: '操作',
					key: 'action',
					align: 'center',
					render: (text, record) => (
						<Space size='middle'>
							{buttonArr.indexOf('role_update') > -1 &&
								<Tag color='blue' className='pointer' icon={<EditOutlined />}
									onClick={this.modifyGroup.bind(this, 'edit', record)}>编辑</Tag>
							}
							{buttonArr.indexOf('role_delete') > -1 &&
								<Tag color='volcano' className='pointer' icon={<DeleteOutlined />}
									onClick={this.deleteGroup.bind(this, record)}>删除</Tag>
							}
							{buttonArr.indexOf('role_setting') > -1 &&
								<Tag color='default' className='pointer' icon={<SettingOutlined />}
									onClick={this.settingRole.bind(this, record)}>设置权限</Tag>
							}
						</Space>
					),
				});
		}
		this.setState({
			column: columns
		});
	}
	fetchGroupList = async () => {
		try {
			const res = await getGroupList();
			this.setState({
				groupList: res
			});
		} catch (e) {

		}
	}
	fetchGroupPageList = async () => {
		const { groupId, pageSize, pageNo } = this.state;
		let params = {
			groupId,
			pageSize,
			pageNo
		};
		this.setState({
			showTableLoading: true
		});
		try {
			const res = await getPageGroupList(params);
			this.setState({
				groupPageList: res.data,
				total: res.total
			});
		} catch (e) {
		}
		this.setState({
			showSearchLoading: false,
			showTableLoading: false
		});
	}
	fetchTreeList = async () => {
		let treeFormatList = [];
		let treeList = await getTree();
		treeFormatList = this.changeTreeDate(treeList, 'desc', 'title');
		treeFormatList = this.changeTreeDate(treeFormatList, 'childMenuList', 'children');
		treeFormatList = this.changeTreeDate(treeFormatList, 'id', 'key');
		this.setState({
			treeList: treeFormatList
		});
	}
	// 搜索项变化触发
	searchInfoChange = (type, e) => {
		let { groupId } = this.state;
		const value = e.target ? (e.target.value).trim() : e;

		switch (type) {
			case 'groupId': {
				groupId = value;
				break;

			}
		}
		this.setState({
			groupId
		}, () => {
			this.search();
		});
	}
	onPageChange = (page, pageSize) => {
		this.setState({
			pageNo: page,
			pageSize
		}, () => {
			this.fetchGroupPageList();
		});
	}
	lookGroupDetail = (group) => {
		let groupName = group.groupName || '';
		let groupDesc = group.groupDesc || '';

		Modal.warning({
			title: '查看用户',
			okText: '确定',
			icon: null,
			content: (
				<>

					<Row align='middle'>
						<Col span={8} className='label'>角色名称:</Col>
						<Col span={14} className='value'>
							<span>{groupName}</span>
						</Col>
					</Row>
					<Row align='middle'>
						<Col span={8} className='label'>角色描述:</Col>
						<Col span={16} className='value'>
							<span>{groupDesc}</span>
						</Col>
					</Row>


				</>)
		});
	}
	modifyGroup = (type, group) => {
		let groupName = group.groupName || '';
		let groupDesc = group.groupDesc || '';
		let groupInfoChange = (type, e) => {
			const value = e.target ? (e.target.value).trim() : e;
			switch (type) {
				case 'groupName': {
					groupName = value;
					break;
				}
				case 'groupDesc': {
					groupDesc = value;
					break;
				}
			}
		};
		this.setState({
			showModal: true,
			modalTitle: '新增角色',
			modalContent: (
				<>
					<Row style={{ marginBottom: 20 }} align='middle'>
						<Col span={4} className='label'>角色名称</Col>
						<Col span={16} className='value'>
							<Input placeholder='输入角色名称' allowClear
								className='search-device-name'
								defaultValue={groupName}
								onChange={groupInfoChange.bind(this, 'groupName')} />
						</Col>
					</Row>
					<Row style={{ marginBottom: 20 }} align='middle'>
						<Col span={4} className='label'>角色描述</Col>
						<Col span={16} className='value'>
							<TextArea
								placeholder='输入角色描述'
								onChange={groupInfoChange.bind(this, 'groupDesc')}
								rows={6}
								defaultValue={groupDesc}
								allowClear
								showCount
								maxLength={100}
							/>
						</Col>
					</Row>
				</>),
			handleOk: async () => {
				if (groupName.length === 0) {
					message.error('请填写角色名称');
					return;
				}
				if (groupDesc.length === 0) {
					message.error('请填写角色描述');
					return;
				}

				let params = {
					groupName,
					groupDesc,
				};
				this.setState({
					confirmLoading: true,
				});
				if (type === 'add') {
					params.clientId = 0;
					try {
						await addGroup(params);
						message.success('新增角色成功');
					} catch (error) {
					}
				} else if (type === 'edit') {
					params.groupId = group.groupId;
					try {
						await editGroup(params);
						message.success('修改角色成功');
					} catch (error) {
					}
				}
				this.fetchGroupList();
				this.fetchGroupPageList();
				this.setState({
					confirmLoading: false,
					showModal: false
				});
			},
			handleCancel: () => {
				this.setState({
					showModal: false
				});
			}
		});
	}
	changeTreeDate = (arrayJsonObj, oldKey, newKey) => {
		let str = JSON.stringify(arrayJsonObj);
		let reg = new RegExp(oldKey, 'g');
		let newStr = str.replace(reg, newKey);
		return JSON.parse(newStr);
	}
	recursion = (dataSource, list) => {
		dataSource && dataSource.map((menu, index) => {
			if (menu.id === 1001) {
				list.push(menu.id);
			}
			if (menu.level === 3 || menu.level === 4) {
				list.push(menu.id);
			}
			if (menu.level === 2 && !menu.childMenuList) {
				list.push(menu.id);
			}
			if (menu.childMenuList) {
				this.recursion(menu.childMenuList, list);
			}
		});
		return list;
	}
	recursionOrigin = (dataSource, list) => {
		dataSource && dataSource.map((menu, index) => {
			list.push(menu.id);
			if (menu.childMenuList) {
				this.recursionOrigin(menu.childMenuList, list);
			}
		});
		return list;
	}
	settingRole = async (group) => {
		const { treeList } = this.state;
		const { groupId } = group;
		// let roleList = [];
		// let selectedRoleList = [];
		// let options = [];
		// let defaultValue = [];
		let finalSelectedRoleList = [];
		let params = {
			groupId
		};
		let originMenuIds = [];
		let permitTreeFormatList = [];
		let permitTreeList = await getGroupPermitTree(params);
		originMenuIds = this.recursionOrigin(permitTreeList, []);
		permitTreeFormatList = this.recursion(permitTreeList, []);
		// defaultValue = this.recursion(treeList, []);

		// roleList = await getRoleList();
		// roleList.map((item, idx) => {
		// 	let temp = {};
		// 	temp.label = item.roleDesc;
		// 	temp.value = item.roleId;
		// 	options.push(temp);
		// });
		// selectedRoleList = await getRoleList(params);
		// selectedRoleList.map((item, idx) => {
		// 	defaultValue.push(item.roleId);
		// });
		finalSelectedRoleList = originMenuIds;
		let roleInfoChange = (checkedKeys, info) => {
			finalSelectedRoleList = [...checkedKeys, ...info.halfCheckedKeys];
		};

		this.setState({
			showModal: true,
			modalTitle: '设置权限',
			modalContent: (
				<>
					<Tabs defaultActiveKey='1'>
						<TabPane tab='操作权限' key='1'>
							<Tree
								checkable
								// checkStrictly={true}
								defaultExpandAll
								style={{ height: 500, overflow: 'auto' }}
								defaultCheckedKeys={permitTreeFormatList}
								onSelect={roleInfoChange}
								onCheck={roleInfoChange}
								treeData={treeList}
							/>
						</TabPane>
						<TabPane tab='数据权限' disabled key='2'>
							敬请期待
						</TabPane>
						<TabPane tab='字段权限' disabled key='3'>
							敬请期待
						</TabPane>
					</Tabs>
				</>),
			handleOk: async () => {
				if (finalSelectedRoleList.length === 0) {
					message.info('请至少选择一项');
					return;
				}
				let params = {
					groupId,
					originMenuIds,
					bindMenuIds: finalSelectedRoleList
				};
				this.setState({
					confirmLoading: true,
				});
				try {
					await roleBindGroup(params);
					message.success('设置权限成功');
					window.location.reload();
				} catch (error) {
				}
				this.setState({
					confirmLoading: false,
					showModal: false
				});
			},
			handleCancel: () => {
				this.setState({
					showModal: false
				});
			}
		});
	}

	deleteGroup = (group) => {
		const { groupId, groupName } = group;
		let params = {
			groupId,
			enabled: false
		};
		this.setState({
			showModal: true,
			modalTitle: '删除角色',
			modalContent: (
				<>
					<p>是否确定删除该角色？</p>
					<Row className='padcode' style={{ marginBottom: 10 }}>
						<Col span={4} className='label'>角色名称:</Col>
						<Col span={16} className='value'>{groupName}</Col>
					</Row>
				</>),
			handleOk: async () => {
				try {
					await editGroup(params);
					message.success('删除角色成功');
					this.fetchGroupList();
					this.fetchGroupPageList();
					this.setState({
						showModal: false
					});
				} catch (error) {
				}
			},
			handleCancel: () => {
				this.setState({
					showModal: false
				});
			}
		});
	}
	search = () => {
		this.setState({
			pageNo: 1
		}, () => {
			this.fetchGroupPageList();
		});
	}
	render() {
		const cssPre = 'config';
		const { groupList, groupPageList, modalTitle, showModal, handleOk, pageNo, column,
			handleCancel, confirmLoading, modalContent, showTableLoading, showSearchLoading, total } = this.state;
		const culumnsData = columns.map((item) => {
			return item.title;
		});
		const culumnsSelectData = column.map((item) => {
			return item.title;
		});
		const columnsChange = (list) => {
			let temp = [];
			temp = columns.filter((item) => {
				return list.indexOf(item.title) !== -1;
			});
			this.setState({
				column: temp
			});
		};

		return (
			<div className={`${cssPre}`}>
				<div className={`${cssPre}-header`}>
					<h4 className='title'>权限配置</h4>
					<Breadcrumb>
						<Breadcrumb.Item>系统设置</Breadcrumb.Item>
						<Breadcrumb.Item>权限配置</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className={`${cssPre}-content`}>
					<div className='block'>
						<div className='search'>
							<div className='search-ida' style={{ marginRight: 10 }}>
								<span>用户角色：</span>
								<Select onChange={this.searchInfoChange.bind(this, 'groupId')}
									showSearch
									style={{ width: 200 }}
									defaultValue=''
									placeholder='请选择用户角色'>
									<Option value=''>所有</Option>

									{
										groupList.map((item, index) => {
											return <Option value={item.groupId}>{item.groupName}</Option>;
										})
									}
								</Select>
							</div>
							{/* <Button type='primary' icon={<SearchOutlined />} loading={showSearchLoading}
								onClick={this.search}>查询</Button> */}
						</div>
						<div className='operation'>
							<div className='left'>
								{buttonArr.indexOf('role_add') > -1 &&
									<Button type='primary' onClick={this.modifyGroup.bind(this, 'add')}>
										新增角色
									</Button>
								}
							</div>
							<div className='right'>
								<ColumnSetting options={culumnsData} selectedRowKeys={culumnsSelectData}
									onChange={columnsChange}></ColumnSetting>
							</div>
						</div>


					</div>
					<div className='result'>
						<Table columns={column} dataSource={groupPageList} loading={showTableLoading}
							showSorterTooltip={false}
							pagination={{
								showSizeChanger: true,
								showQuickJumper: true,
								current: pageNo,
								showTotal: total => { return `总计${total}项`; },
								onChange: this.onPageChange,
								total: total
							}}

						/>

					</div>
				</div>
				<Modal title={modalTitle} visible={showModal} onOk={handleOk} onCancel={handleCancel} okText='确认'
					cancelText='取消'
					destroyOnClose={true}
					confirmLoading={confirmLoading}>
					{modalContent}
				</Modal>
			</div>
		);
	}
}

export default AuthConfig;

