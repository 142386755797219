import { Line } from '@ant-design/charts';

const Charts = ({ data }) => {
    const config = {
        data,
        height: 280,
        xField: '时间',
        yField: '功率（W）',
        point: {
            sizeField: 1,
        }
    };
    return <Line {...config} />;
};

export { Charts };
