import React from 'react';
import 'antd/dist/antd.css';
import './index.less';
// 引入 ECharts 主模块
import * as echarts from 'echarts/core';
import {
    LineChart
} from 'echarts/charts';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';

echarts.use(
    [TitleComponent, ToolboxComponent, TooltipComponent, GridComponent,
        LegendComponent, MarkLineComponent, MarkPointComponent, LineChart, CanvasRenderer]
);

class DrawFlowStaticsChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
    }
    componentDidMount() {
        const { dataSource } = this.props;
        this.setState({
            dataSource
        }, () => {
            this.drawChart();
        });
    }
    componentWillReceiveProps(nextProps) {
        const { dataSource } = nextProps;
        this.setState({
            dataSource
        }, () => {
            this.drawChart();
        });
    }
    drawChart = () => {
        const { dataSource } = this.state;
        let chartDom = document.getElementById('echartsRadar');
        let myChart = echarts.init(chartDom);
        let option;
        option = {
            xAxis: {
                type: 'category',
                data: ['轻微', '严重', '紧急']
            },
            yAxis: { type: 'value' },
            series: [
                {
                    data: [dataSource.lightCount, dataSource.seriousCount, dataSource.urgentCount],
                    type: 'line'
                }
            ]
        };

        option && myChart.setOption(option);
    }

    render() {

        return (
            <div className='echartsRadar' id='echartsRadar'>

            </div>
        );
    }
}

export default DrawFlowStaticsChart;