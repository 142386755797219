import { Menu, Button, Dropdown, Modal, Avatar } from 'antd';
import React from 'react';
import { history } from '../../_helpers';
import { connect } from 'react-redux';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import './index.less';


class User extends React.Component {
	componentDidMount() {
	}

	render() {
		const { user } = this.props;
		const menu = (
			<Menu style={{ textAlign: 'center' }}>
				<Menu.Item
					onClick={() => {
						Modal.confirm({
							title: '提醒',
							content: '确定要退出登录?',
							cancelText: '取消',
							okText: '确定',
							onOk() {
								history.push('/user/login');
							}
						});
					}}
				>
					<div><LogoutOutlined style={{ marginRight: 5 }} />退出</div>
				</Menu.Item>
			</Menu>
		);
		return (
			<div style={{ float: 'right', marginTop: 0 }}>
				<Avatar style={{
					backgroundColor: '#001529',
					marginRight: 5
				}}
					icon={<UserOutlined />} />
				<Dropdown overlay={menu} placement='bottomCenter' trigger={['click']}>
					<Button type='link' style={{ padding: 0 }}>{user && user.username}</Button>
				</Dropdown>
				<span className='triangle'></span>
			</div>
		);
	}
}

function mapState(state) {
	const { authentication } = state;
	const { user } = authentication;
	return { user };
}

const actionCreators = {};
const connectedUser = connect(mapState, actionCreators)(User);
export { connectedUser as User };